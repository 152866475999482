import React, { useEffect, useState } from "react";

import './SiteNav.scss';
import { AppBar, Toolbar, Typography, Box, IconButton, Menu, MenuItem, Container, Tooltip, Badge, Link } from "@mui/material";

import { useAppSelector, useAppDispatch } from '../../redux/hooks';

import MenuIcon from '@mui/icons-material/Menu';

import AccountCircle from '@mui/icons-material/AccountCircle';

import { Link as RouterLink, useNavigate } from 'react-router-dom';

// import { v4 as uuidv4 } from 'uuid';
import { useAuth0 } from "@auth0/auth0-react";
import { getUser, setCurrentUser } from "../../redux/sliceUser";
import { gqlSetIdentity } from "../../gql";
import { gql, useLazyQuery } from "@apollo/client";
import { isDiscogsAuthorized, clearDiscogsInfo, setDiscogsInfo } from "../../redux/sliceDiscogs";
import secureLocalStorage from "react-secure-storage";

import { getUnreadCount } from "../../redux/sliceNotifications";

import logo from '../../img/logowhite.png'
import MenuGroupButton from "./MenuGroupButton";
import { AUTH_ROLES } from "../../constants";
import NotificationPopover from "../Notifications/NotificationPopover";

const SiteNav = () => {
    const user = useAppSelector((state) => getUser(state));
    const unreadCount = useAppSelector((state) => getUnreadCount(state));

    const { loginWithRedirect, logout, isAuthenticated, user:auth0User } = useAuth0();
    const nav = useNavigate();
    const dispatch = useAppDispatch();

    const token = useAppSelector((state) => state?.discogs?.oauth_token);
    const secret = useAppSelector((state) => state?.discogs?.oauth_secret);
    const verifier = useAppSelector((state) => state?.discogs?.oauth_verifier);
    const discogsAuthorized = useAppSelector((state) => isDiscogsAuthorized(state));
    const [justLoggedOut, setJustLoggedOut] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const identityQuery = gqlSetIdentity(token, secret, verifier);
    const [setUserData, {error, data}] = useLazyQuery(gql`${identityQuery.query}`, {variables:identityQuery.variables});

    useEffect(() => {

        if (auth0User) {
            const roleKey = `${process.env.REACT_APP_AUTH0_AUDIENCE}/roles`;
            const userRoles = auth0User[roleKey];
            if (userRoles) {
                userRoles.forEach((role: string) => {
                    if (role === AUTH_ROLES.ARAdmin)
                        setIsAdmin(true);
                })
            }
        }
    }, [auth0User])

    useEffect(() => {
        if (isAuthenticated && discogsAuthorized && !user?._id) {
            setUserData();
        }

        if (isAuthenticated && !discogsAuthorized && !justLoggedOut) {
            const tokenInfo = secureLocalStorage.getItem(process.env.REACT_APP_STORAGE_KEY_DISCOGS_TMP!)?.toString();
            if (tokenInfo) {
                
                const info = JSON.parse(tokenInfo);
                dispatch(setDiscogsInfo(info));
                secureLocalStorage.removeItem(process.env.REACT_APP_STORAGE_KEY_DISCOGS_TMP!);
            }
        }

        
    }, [isAuthenticated, user, setUserData, dispatch, discogsAuthorized, justLoggedOut])

    useEffect(() => {
        if (data)
            dispatch(setCurrentUser(data?.setIdentity?.user));

        if (error)
            console.error(error);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error, dispatch])

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogin = () => {
        loginWithRedirect();
    }

    const handleLogout = () => {
        const tmp = JSON.stringify({
            oauth_token: token,
            oauth_secret: secret,
            oauth_verifier: verifier
        });
        secureLocalStorage.setItem(process.env.REACT_APP_STORAGE_KEY_DISCOGS_TMP!, tmp);

        setJustLoggedOut(true);
        dispatch(setCurrentUser({}));
        dispatch(clearDiscogsInfo());
        
        logout({ logoutParams: { returnTo: window.location.origin } });
    }

    const handleDiscogsAuth = () => {
        setAnchorElUser(null);
        nav("../../discogs");
    }

    const handleDiscogsProfile = () => {
        setAnchorElUser(null);
        nav("../../discogs/profile");
    }

    let pages:any = [];
    let tools:any = [];
    let pagesSmall:any = [];

    if (discogsAuthorized) {
        pages = [
            { id: 10, name: "Collection", link: "../collection", title: "View to your collection"},
            { id: 20, name: "Queue", link: "../queue", title: "View to your queue"},
            { id: 30, name: "Timeline", link: "../timeline", title: "View to your timeline"},
            { id: 40, name: "Friends", link: "../friends", title: "View to your friends"},
        ];

        tools = [
            { id: 100, name: "Suggest", link: "../suggest", title: "Suggest Albums" },
            { id: 200, name: "Search Discogs", link: "../discogs/search", title: "Search Discogs" }
        ];

        pagesSmall = [
            { id: 10, name: "Collection", link: "../collection", title: "View to your collection"},
            { id: 20, name: "Queue", link: "../queue", title: "View to your queue"},
            { id: 30, name: "Timeline", link: "../timeline", title: "View to your timeline"},
            { id: 40, name: "Friends", link: "../friends", title: "View to your friends"},
            { id: 50, name: "Suggest", link: "../suggest", title: "Suggest Albums" },
            { id: 60, name: "Search Discogs", link: "../discogs/search", title: "Search Discogs" }
        ];
        
    }

    const handleMenuItemNotifications = () => {
        setAnchorElUser(null);
        nav("../notifications");
    }

    const handleAdmin = () => {
        setAnchorElUser(null);
        nav("../admin");
    }

    return (
        <div className="nav-container">

            <div className="nav-row">
                
                <AppBar position="static" sx={{ 
                        backgroundColor: '#404040', 
                        boxShadow: '0'
                    }}>
                    <Container maxWidth="xl" sx={{
                        '@media (min-width: 600px)': {
                            paddingLeft: '0px',
                            paddingRight: '0px'
                        },
                        paddingLeft: '0px',
                        paddingRight: '0px'
                    }}>
                        <Toolbar disableGutters>

                            <Typography
                                variant="h6"
                                noWrap
                                component="a"
                                href="/"
                                sx={{
                                    mr: 2,
                                    display: { xs: 'none', md: 'flex' },
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                            >
                                
                                <img src={logo} height="100px" alt={`Logo for ${process.env.REACT_APP_SITE_NAME}`}  />
                            </Typography>

                            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                    sx={{
                                        paddingLeft: '0px'
                                    }}
                                >
                                <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: { xs: 'block', md: 'none' },
                                    }}
                                >
                                    {/* small screen menu */}
                                    {pagesSmall?.map((page:any) => (
                                        <MenuItem key={page.id} onClick={handleCloseNavMenu}>
                                            <Link component={RouterLink} to={`${page?.link}`}>{page.name}</Link>
                                            {/* <Typography textAlign="center" 
                                                component="a" 
                                                href={page.link}
                                                title={page.title}
                                                sx={{
                                                    textDecoration: "none",
                                                }}
                                            >
                                                {page.name}
                                            </Typography> */}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>

                            <Typography
                                variant="h5"
                                noWrap
                                component="a"
                                href="#app-bar-with-responsive-menu"
                                sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                                }}
                            >
                                <img src={logo} height="50px" alt={`Logo for ${process.env.REACT_APP_SITE_NAME}`} />
                            </Typography>
                            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                {/* large screen upper nav menu */}
                                { (isAuthenticated && discogsAuthorized && user?._id) &&
                                    <>
                                        <MenuGroupButton menuTitle="My Audio Recall" menuItems={pages} />
                                        <MenuGroupButton menuTitle="Tools" menuItems={tools} />
                                    </>
                                }
                            </Box>

                            <Box sx={{ flexGrow: 0 }}>
                                <Typography>
                                    
                                </Typography>
                                <Typography component={'span'} className="nav-username" 
                                    sx={{ 
                                        flexGrow: 1, 
                                        display: { xs: 'none', md: 'inline' },
                                        fontSize: '1.5rem'
                                    }}
                                >
                                    {/* { (isAuthenticated && discogsAuthorized && user?._id)
                                        && <Notifications viewType={NotificationViewType.POPOVER} getUnread={true} />
                                    } */}

                                    { (isAuthenticated && discogsAuthorized && user?._id)
                                        && <NotificationPopover />
                                    }
                                    <span className="username-span">{user?.discogsUsername}</span>
                                </Typography>
                                <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    
                                    <Badge badgeContent={unreadCount} color="primary" showZero={false}
                                        sx={{ display: { md: 'none'}}} >
                                        { user?.discogsUserAvatar && 
                                            <img className='avatar-image' alt="Your avatar" src={user?.discogsUserAvatar} height={40} width={40} 
                                        />}
                                    </Badge>

                                    <Typography sx={{ display: { xs: 'none', sm: 'none', md: 'inline'}}}>
                                        { user?.discogsUserAvatar && 
                                            <img className='avatar-image' alt="Your avatar" src={user?.discogsUserAvatar} height={40} width={40} 
                                        />}
                                    </Typography>

                                    { (!user || !Object?.keys(user)?.length) && 
                                        <span><AccountCircle /></span>
                                    }
                                </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >

                                    { (isAuthenticated && user?._id && isAdmin === true) && 
                                        <MenuItem onClick={handleAdmin}>
                                            <Typography>Admin</Typography>
                                        </MenuItem>
                                    }

                                    { (isAuthenticated && !user?._id) && 
                                        <MenuItem onClick={handleDiscogsAuth}>
                                            <Typography>Discogs Authorization</Typography>
                                        </MenuItem>
                                    }

                                    { (isAuthenticated && user?._id) && 
                                        <MenuItem onClick={handleMenuItemNotifications} sx={{ display: { md: 'none' }}}>
                                            <Typography>View notifications ({unreadCount} new)</Typography>
                                        </MenuItem>
                                    }

                                    { (isAuthenticated && user?._id) && 
                                        <MenuItem onClick={handleDiscogsProfile}>
                                            <Typography>My Discogs Profile</Typography>
                                        </MenuItem>
                                    }

                                    { (isAuthenticated) && 
                                        <MenuItem onClick={handleLogout}>
                                            <Typography>Logout of {process.env.REACT_APP_SITE_NAME}</Typography>
                                        </MenuItem>
                                    }

                                    { (!isAuthenticated) && 
                                        <MenuItem onClick={handleLogin}>
                                            <Typography>Login to {process.env.REACT_APP_SITE_NAME}</Typography>
                                        </MenuItem>
                                    }

                                </Menu>

                            </Box>

                        </Toolbar>

                    </Container>

                </AppBar>

            </div>

        </div>
    )
}

export default SiteNav;