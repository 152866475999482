import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

interface GlobalLoadingState {
    loading: boolean;
}

const initialState:GlobalLoadingState = {
    loading: false
}

const globalLoadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        setLoading: (state, action:PayloadAction<boolean>) => {
            state.loading = action.payload;
        }
    }
});

export const { setLoading } = globalLoadingSlice.actions;

export const getLoading = (state: RootState) => state.globalLoading.loading;

export default globalLoadingSlice.reducer;