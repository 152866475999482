import React from 'react';
import { useParams } from 'react-router-dom';
import { gqlDiscogsArtist } from '../../../gql';
import { gql, useQuery } from '@apollo/client';
import { useAppDispatch } from '../../../redux/hooks';
import { setLoading } from '../../../redux/sliceLoading';
import { Grid, Link, Skeleton } from '@mui/material';

import { Link as RouterLink } from "react-router-dom";

import { v4 as uuidv4 } from 'uuid';

const DiscogsArtist:React.FunctionComponent = () => {
    const params = useParams();
    const id = params?.id ? parseInt(params?.id) : 0;
    const topRef = React.useRef<null | HTMLDivElement>(null);

    const [apiData, setApiData] = React.useState<any>();
    const [masterReleases, setMasterReleases] = React.useState<any>();
    const [releases, setReleases] = React.useState<any>();

    const queryArtist = gqlDiscogsArtist(id);
    const {loading, error, data} = useQuery(gql`${queryArtist.query}`, {
        variables: queryArtist.variables
    });

    // use redux toolkit dispatch
    const dispatch = useAppDispatch();
    React.useEffect(() => {
        dispatch(setLoading(loading));
    }, [loading, dispatch])

    React.useEffect(() => {

        if (data?.getDiscogsArtist) {
            setApiData(data?.getDiscogsArtist);

            const masterReleases = data?.getDiscogsArtist?.releases?.filter(
                (item: { role: string; type: string, main_release: number; }) => item?.type === 'master');
            setMasterReleases(masterReleases);
            
            const releases = data?.getDiscogsArtist?.releases?.filter(
                (item: { role: string; type: string, main_release: number; }) => item?.type === 'release');
            setReleases(releases);

            console.log("releases", releases);
        }

        if (error) {
            console.error(error);
        }

    }, [data, error]);

    React.useEffect(() => {
        topRef?.current?.scrollIntoView({behavior: 'smooth'});
    }, [])

    return (
        <>
            <div ref={topRef}></div>
            <Grid container className='page-header-row' mb={3}>
                <Grid item xs={12}>
                    { apiData && <h1>{apiData?.artist?.name}</h1> }
                    { !apiData && <Skeleton variant="rounded" width={500} height={48} /> }
                </Grid>
            </Grid>
            
            <Grid container mb={3}>
                <Grid item xs={6} md={2}>
                    { apiData && 
                        <img src={`${apiData?.artist?.images?.filter((item: { type: string; }) => item?.type === 'primary')[0]?.uri}`}
                            alt={`${apiData?.artist?.name}`}
                            width={150}
                        />
                    }
                    { !apiData && <Skeleton variant="rectangular" width={150} height={150} /> }
                </Grid>
                <Grid item xs={6} md={10}>
                    {/* {apiData?.artist?.profile}
                    { !apiData && <Skeleton variant="rectangular" width={'100%'} height={150} /> } */}
                </Grid>
            </Grid>

            <Grid container mb={1}>
                <Grid item xs={12}>
                    { apiData && <h2>Releases</h2> }
                    { !apiData && <Skeleton variant="rounded" width={200} height={38} /> }
                </Grid>
            </Grid>

            {!apiData && 
                <>
                    <Grid container mb={1}>
                        <Grid item xs={3} md={1}>
                            <Skeleton variant="rectangular" width={75} height={75} />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Skeleton variant="rectangular" width={'50%'} height={22} />
                        </Grid>
                        <Grid item xs={2} md={2}>
                            <Skeleton variant="rectangular" width={'50%'} height={22} />
                        </Grid>
                    </Grid>

                    <Grid container mb={1}>
                        <Grid item xs={3} md={1}>
                            <Skeleton variant="rectangular" width={75} height={75} />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Skeleton variant="rectangular" width={'50%'} height={22} />
                        </Grid>
                        <Grid item xs={2} md={2}>
                            <Skeleton variant="rectangular" width={'50%'} height={22} />
                        </Grid>
                    </Grid>
                </>
            }
            
            {masterReleases?.map(function(item:any) {
                return (
                    <Grid container key={uuidv4()} mb={1}>
                        <Grid item xs={3} md={1}>
                            <img src={`${item?.thumb}`} width={75} alt={`${item?.title}`} />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Link component={RouterLink} to={`../master/${item?.id}`}>
                                {item?.title?.split(' - ')[1]}
                            </Link>
                        </Grid>
                        <Grid item xs={2} md={2}>
                            {releases?.filter((r: { master_id: number; }) => r?.master_id === item?.id).length} version(s)
                        </Grid>
                    </Grid>
                )
            })}
            
        </>
    )
}

export default DiscogsArtist;