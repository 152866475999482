import { Grid } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const AdminLanding:React.FunctionComponent = () => {

    return (
        <>
            <Grid container className='page-header-row' mb={3}>
                <Grid item xs={12}><h1>Admin</h1></Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <Link to={'../admin/contactmessages'}>Contact Us Messages</Link>
                </Grid>
            </Grid>
        </>
    )
};

export default AdminLanding;