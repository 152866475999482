import React from 'react';

const Logout:React.FunctionComponent = () => {
    // const { logout } = useAuth0();
    
    return (
        <>
            {/* <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>Logout</button> */}
        </>
    )
}

export default Logout;