import React, { useEffect } from 'react';

import './Layout.scss';
import { Outlet, useLocation } from 'react-router-dom';
import Footer from './Footer';
import SiteNav from './SiteNav';
import { Alert, Grid, LinearProgress } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getLoading } from '../../redux/sliceLoading';
import { getAlertState, setAlert } from '../../redux/sliceAlert';

const Layout: React.FunctionComponent = () => {
    const isLoading = useAppSelector((state) => getLoading(state));
    const alertState = useAppSelector((state) => getAlertState(state));

    const location = useLocation();
    const dispatch = useAppDispatch();
    
    useEffect(() => {
        // reset the error on location change
        dispatch(setAlert({ severity: 'error', message: '', show: false }))
    }, [location, dispatch])

    return (
        <div>
            <SiteNav />

            <div className='global-linear-progress'>{ isLoading && <LinearProgress />}</div>

            <div className='main-content-container'>

                { alertState?.show === true && 
                    <Grid container mb={1}>
                        <Grid item xs={12} className='main-content-row'>
                            <Alert severity={alertState?.severity}>{alertState?.message}</Alert>
                        </Grid>
                    </Grid>
                }

                <Grid container>
                    <Grid item xs={12} className='main-content-row'><Outlet /><br /><br /><br /></Grid>
                </Grid>
            </div>

            <Footer />
        </div>
    )
}

export default Layout;