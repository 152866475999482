import { Breadcrumbs, Grid, LinearProgress, Typography } from '@mui/material';

import React, { useEffect, useRef, useState } from 'react';

import './TimelineRelease.scss';

import TimelineIcon from '@mui/icons-material/Timeline';
import { useParams, Link } from 'react-router-dom';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { gqlGetRelease, gqlUserListens } from '../../gql';
import { gql, useQuery } from '@apollo/client';
import { determineListeningTime, secondsToHms } from '../../utils/dates';
import TimelineLayout from '../TimelineLayout/TimelineLayout';
dayjs.extend(utc);

const TimelineRelease:React.FunctionComponent = () => {
    const params = useParams();
    const topRef = useRef<null | HTMLDivElement>(null);
    const [timelineData, setTimelineData] = useState<any>([]);
    const [listeningTime, setListeningTime] = useState('');
    const [albumInfo, setAlbumInfo] = useState<any>({});
    const [refetchData, setRefetchData] = useState(false);

    const username = params?.id;
    const releaseId = params?.releaseId;

    const [dateRange] = useState<any>({
        start: 0, //dayjs().startOf("year").utc().unix(),
        end: dayjs().endOf("day").utc().unix()
    });

    const releaseQuery = gqlGetRelease(parseInt(releaseId!));
    const {loading:releaseLoading, error:releaseError, data:releaseData} = useQuery(gql`${releaseQuery.query}`, {
        variables: releaseQuery.variables,
        fetchPolicy: 'cache-first'
    });

    const query = gqlUserListens(dateRange, username, parseInt(releaseId!));
    const {loading, error, data, refetch} = useQuery(gql`${query.query}`, {
        variables: query.variables,
        fetchPolicy: 'cache-and-network'
    });

    useEffect(() => {

        if (refetchData === true) {
            refetch();
            setRefetchData(false);
        }

    }, [refetchData, refetch])

    useEffect(() => {

        if (releaseData) {
            setAlbumInfo(releaseData?.getRelease);
        }

        if (releaseError) {
            console.error(releaseError);
        }

    }, [releaseData, releaseError])

    useEffect(() => {

        if (data) {
            setTimelineData(data?.getListens);

            const total = determineListeningTime(data?.getListens);
            setListeningTime(secondsToHms(total));

            topRef?.current?.scrollIntoView({behavior: 'smooth'});
        }

        if (error) {
            console.error(error);
        }

    }, [data, error])
    
    return (
        <div>
            <div ref={topRef}></div>
            <Grid container className='page-header-row' mb={3}>
                <Grid item xs={12}><h1>Album Timeline <TimelineIcon fontSize="large" /></h1></Grid>
            </Grid>

            <Breadcrumbs aria-label="breadcrumb" className='breadcrumbs-custom'>
                <Link color="inherit" to="/">
                    Home
                </Link>
                <Link
                    color="inherit"
                    to={`../../../timeline`}
                >
                    Timeline
                </Link>
                <Link
                    color="inherit"
                    to={`../../../timeline/user/${username}`}
                >
                    {username}
                </Link>
                <Typography color="text.primary">{albumInfo?.title}</Typography>
            </Breadcrumbs>

            { (loading || releaseLoading ) && 
                <Grid container>
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <LinearProgress color="secondary" />
                    </Grid>
                </Grid>
            }

            <Grid container mt={6} mb={3}>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <img src={albumInfo?.thumb} 
                        alt={`Thumbnail for ${albumInfo?.title}`} 
                        title={`Thumbnail for ${albumInfo?.title}`} />
                </Grid>
            </Grid>

            <Grid container mt={3} mb={3}>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    Total listening time: {listeningTime}
                </Grid>
            </Grid>

            <TimelineLayout 
                timelineData={timelineData}
                username={username!}
                setTimelineData={setTimelineData}
                setRefetchData={setRefetchData} />

        </div>
    )
}

export default TimelineRelease;