import React, { useEffect, useState } from 'react';

import './CollectionMenu.scss';
import { Box, Button } from '@mui/material';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import TimelineIcon from '@mui/icons-material/Timeline';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { gqlAddToQueue } from '../../gql';
import { gql, useMutation } from '@apollo/client';
import CustomSnackBar, { ICustomSnackState } from '../custom-snack-bar/CustomSnackBar';

import MusicNoteIcon from '@mui/icons-material/MusicNote';
import AddToQueueButton from '../AddToQueueButton/AddToQueueButton';
import { setLoading } from '../../redux/sliceLoading';

interface ICollectionMenu {
    item: any;
}

const smallOptions = [
    { id: 10, name: 'Add to your queue'},
    { id: 20, name: 'View album timeline'},
    { id: 100, name: 'View on Discogs'}
];

const largeOptions = [
    { id: 20, name: "View album timeline"},
    { id: 100, name: "View on Discogs"}
]

const ITEM_HEIGHT = 48;

const CollectionMenu:React.FunctionComponent<ICollectionMenu> = ({ item }) => {
    const user = useAppSelector((state) => state.user.value);

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const ellipsisOpen = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    let itemId:number = 0;
    if (item?.basic_information)
        itemId = item?.basic_information?.id;
    else 
        itemId = item?.id;
    
    const handleClose = (id:number) => {
        setAnchorEl(null);

        switch (id) {
            case 10:
                addToQueue();
                break;
            case 20: 
                navigate(`../timeline/user/${user?.discogsUsername}/${itemId}`);
                break;
            case 100: 
                window.open(`https://www.discogs.com/release/${itemId}`, '_blank', 'noreferrer');
                break;
            default:
                break;
        }

    };

    const addQuery = gqlAddToQueue(itemId);
    const [addToQueue, { loading, error, data}] = useMutation(gql`${addQuery.query}`, {
        variables: addQuery.variables,
        fetchPolicy: 'no-cache'
    });
    const [snackState, setSnackState] = useState<ICustomSnackState>({ open: false, 
        severity: "success", message: "", hideDuration: 5000});

    useEffect(() => {

        if (data?.addToQueue?.success === true) {
            setSnackState({ ...snackState, open: true, severity: "success", message: "Queue entry added!"});
            console.log(data?.addToQueue);
        } else if (data?.addToQueue?.success === false) {
            setSnackState({ ...snackState, open: true, severity: "error", message: "Queue entry not added!"});
            console.log(data?.addToQueue);
        }

        if (error) {
            setSnackState({ ...snackState, open: true, severity: "error", message: "There was an error adding this album to your queue"});
            console.error(error);
        }

    // eslint-disable-next-line
    }, [error, data])

    // const handleAddToQueue = () => {
    //     addToQueue();
    // }

    const handleAddListen = () => {
        navigate(`../timeline/add/${itemId}`);
    }

    // use redux toolkit dispatch
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setLoading(loading));
    }, [loading, dispatch])

    return (
        <>
            <CustomSnackBar setCustomSnackState={setSnackState} state={snackState} />

            <Button variant='contained' startIcon={<MusicNoteIcon />}
                onClick={handleAddListen} className='listen-button'
                sx={{ marginRight: '0.5rem', display: { xs: 'none', md: 'inline' } }}>
                Add
            </Button>
            <Button variant='contained'
                onClick={handleAddListen} className='listen-button'
                sx={{ marginRight: '0.5rem', display: { md: 'none', xs: 'inline' } }}
                title={'Add Listen'}
                >
                Add
            </Button>

            <Box sx={{ display: { md: 'inline', xs: 'none' }}}>
                <AddToQueueButton id={itemId} setCustomSnackState={setSnackState} />
            </Box>
            
            {/* ellipsis menu */}
            <IconButton
                aria-label="more"
                id="small-menu-button"
                aria-controls={ellipsisOpen ? 'small-menu' : undefined}
                aria-expanded={ellipsisOpen ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="small-menu"
                MenuListProps={{
                'aria-labelledby': 'small-menu-button',
                }}
                anchorEl={anchorEl}
                open={ellipsisOpen}
                onClose={handleClose}
                PaperProps={{
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '20ch',
                },
                }}
            >
                <Box sx={{ display: { md: 'inline', xs: 'none' }}}>
                    {largeOptions.map((option) => (
                        <MenuItem key={option.id} onClick={(e) => handleClose(option.id)}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Box>

                <Box sx={{ display: { md: 'none', xs: 'inline' }}}>
                    {smallOptions.map((option) => (
                        <MenuItem key={option.id} onClick={(e) => handleClose(option.id)}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Box>

            </Menu>
            
        </>
    )
};

export default CollectionMenu;