import React from 'react';

const PrivacyPolicy:React.FunctionComponent = () => {

    return (
        <div>
            Privacy Policy
        </div>
    )
}

export default PrivacyPolicy;