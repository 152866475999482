import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, FormControl, Grid, InputLabel, Link, ListItemText, MenuItem, Select, SelectChangeEvent, Skeleton, Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { gqlGetDiscogsMaster } from '../../../gql';
import { useQuery, gql } from '@apollo/client';
import { useAppDispatch } from '../../../redux/hooks';
import { setLoading } from '../../../redux/sliceLoading';

import { Link as RouterLink } from "react-router-dom";

import { v4 as uuidv4 } from 'uuid';
import CollectionMenu from '../../../components/CollectionMenu/CollectionMenu';
import AlbumImages from '../../../components/AlbumImages/AlbumImages';
import CollectionPagination from '../../../components/CollectionPagination/CollectionPagination';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface IFacetValue {
    title: string;
    value: string;
    count: number;
}

interface IFacet {
    id: string;
    title: string;
    allows_multiple_values: boolean;
    values: IFacetValue[];
}

interface IFilter {
    format: string[];
    label: string[];
    country: string[];
    year: string[];
}

const DiscogsMaster:React.FunctionComponent = () => {
    const params = useParams();
    const masterId = params?.id ? parseInt(params?.id) : 0;
    const topRef = React.useRef<null | HTMLDivElement>(null);
    
    const [masterRelease, setMasterRelease] = React.useState<any>();
    const [releases, setReleases] = React.useState<any>();
    const [page, setPage] = React.useState(1);
    const [allFormats, setAllFormats] = React.useState<IFacet>();
    const [allLabels, setAllLabels] = React.useState<IFacet>();
    const [allCountries, setAllCountries] = React.useState<IFacet>();
    const [allYears, setAllYears] = React.useState<IFacet>();
    const [initialLoad, setInitialLoad] = React.useState(true);

    const [filter, setFilter] = React.useState<IFilter>({
        format:[], label:[], country:[], year:[]
    });
    const [filterOpen, setFilterOpen] = React.useState(false);

    const queryArtist = gqlGetDiscogsMaster(masterId, page, 
        filter?.format?.join(','), filter?.label?.join(','), 
        filter?.country?.join(','), filter?.year?.join(','));
    const {loading, error, data, refetch} = useQuery(gql`${queryArtist.query}`, {
        variables: queryArtist.variables
    });

    // use redux toolkit dispatch
    const dispatch = useAppDispatch();
    React.useEffect(() => {
        dispatch(setLoading(loading));
    }, [loading, dispatch])

    React.useEffect(() => {

        if (data?.getDiscogsMaster) {
            setMasterRelease(data?.getDiscogsMaster?.masterRelease);
            //console.log("masterRelease", mRelease);
            
            // const releases = data?.getDiscogsMaster?.releases?.filter(
            //     (item: { type: string, master_id: number; }) => item?.type === 'release' && item?.master_id === masterId);
            setReleases(data?.getDiscogsMaster?.versions);
            //console.log("versions", data?.getDiscogsMaster?.versions);


            //console.log("facets", data?.getDiscogsMaster?.facets);
            //const facetFormat = data?.getDiscogsMaster?.facets.find((item: { id: string; }) => item.id === 'format');
            //console.log("facetFormat", facetFormat);
            if (initialLoad === true) {
                setAllFormats(data?.getDiscogsMaster?.facets.find((item: { id: string; }) => item.id === 'format'));
                setAllLabels(data?.getDiscogsMaster?.facets.find((item: { id: string; }) => item.id === 'label'));
            }
            setAllCountries(data?.getDiscogsMaster?.facets.find((item: { id: string; }) => item.id === 'country'));
            setAllYears(data?.getDiscogsMaster?.facets.find((item: { id: string; }) => item.id === 'released'));
            
            setInitialLoad(false);
        }

        if (error) {
            console.error(error);
        }

    }, [data, error, masterId, initialLoad]);

    React.useEffect(() => {
        topRef?.current?.scrollIntoView({behavior: 'smooth'});
        refetch();
    }, [page, refetch])

    const handleFormatChange = (event: SelectChangeEvent<typeof filter.format>) => {
        const value = event?.target?.value;
        //setFormat(typeof value === 'string' ? value.split(',') : value);
        setFilter({...filter, format: typeof value === 'string' ? value.split(',') : value});
    }

    const handleLabelChange = (event: SelectChangeEvent<typeof filter.label>) => {
        const value = event?.target?.value;
        //setLabel(typeof value === 'string' ? value.split(',') : value);
        setFilter({...filter, label: typeof value === 'string' ? value.split(',') : value});
    }

    const handleCountryChange = (event: SelectChangeEvent<typeof filter.country>) => {
        const value = event?.target?.value;
        //setCountry(typeof value === 'string' ? value.split(',') : value);
        setFilter({...filter, country: typeof value === 'string' ? value.split(',') : value});
    }

    const handleYearChange = (event: SelectChangeEvent<typeof filter.year>) => {
        const value = event?.target?.value;
        //setYear(typeof value === 'string' ? value.split(',') : value);
        setFilter({...filter, year: typeof value === 'string' ? value.split(',') : value});
    }

    const resetFilters = () => {
        setFilter({ format:[], label:[], country:[], year:[] });
    }

    return (
        <>
            <div ref={topRef}></div>
            <Grid container className='page-header-row' mb={3}>
                <Grid item xs={12}>
                    { data && <h1>{data?.getDiscogsMaster?.artist?.name} - {data?.getDiscogsMaster?.masterRelease?.title}</h1> }
                    { !data && <Skeleton variant="rounded" width={500} height={48} /> }
                </Grid>
            </Grid>

            <Grid container mb={3}>
                <Grid item xs={6} md={2}>
                    { data &&
                        <img src={`${data?.getDiscogsMaster?.masterRelease?.images[0]?.uri}`}
                            alt={`${masterRelease?.title}`}
                            width={150} loading='lazy'
                        />
                    }
                    { !data && <Skeleton variant="rectangular" width={150} height={150} /> }
                </Grid>
                <Grid item xs={6} md={10}>
                    
                </Grid>
            </Grid>

            <Grid container mb={3}>
                <Grid item xs={12}>
                    <h2>Releases</h2>
                </Grid>
            </Grid>

            <Grid container mb={3}>
                <Grid item xs={12} md={12}>
                    <Accordion expanded={filterOpen} onChange={() => setFilterOpen(!filterOpen)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="search-tools"
                            id="search-tools"
                            >
                            <Typography>Filter</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography component={'span'}>
                                <Grid container spacing={2}>
                                
                                    <Grid item xs={12} sm={12} md={3} lg={3}>
                                        <FormControl fullWidth>
                                            <InputLabel>Format</InputLabel>
                                            {/* <Select
                                                labelId="lblFormat"
                                                id="lstFormat"
                                                value={format}
                                                label="Format"
                                                onChange={(e: SelectChangeEvent) => setFormat(e.target.value)}
                                                >
                                                    <MenuItem value=""><em>Any</em></MenuItem>
                                                    { format !== '' && <MenuItem value={format}>{format}</MenuItem>}
                                                    {allFormats?.values?.map(function(item:any) {
                                                        return (
                                                            <MenuItem key={uuidv4()} value={item?.value}>{item?.title} ({item?.count})</MenuItem>
                                                        )
                                                    })}
                                            </Select> */}
                                            <Select
                                                labelId="lblFormat"
                                                id="lstFormat"
                                                value={filter?.format}
                                                label="Format"
                                                onChange={handleFormatChange}
                                                multiple={true}
                                                renderValue={(selected) => selected.join(', ')}
                                                >
                                                    {allFormats?.values?.map((item) => (
                                                        <MenuItem key={uuidv4()} value={item?.title}>
                                                            <Checkbox checked={filter?.format?.indexOf(item?.title) > -1} />
                                                            <ListItemText primary={item.title} />
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={3} lg={3}>
                                        <FormControl fullWidth>
                                            <InputLabel>Labels</InputLabel>
                                            {/* <Select
                                                labelId="lblLabel"
                                                id="lstLabel"
                                                value={label}
                                                label="Labels"
                                                onChange={(e: SelectChangeEvent) => setLabel(e.target.value)}
                                                >
                                                    <MenuItem value=""><em>Any</em></MenuItem>
                                                    {allLabels?.values?.map(function(item:any) {
                                                        return (
                                                            <MenuItem key={uuidv4()} value={item?.value}>{item?.title} ({item?.count})</MenuItem>
                                                        )
                                                    })}
                                            </Select> */}
                                            <Select
                                                labelId="lblLabels"
                                                id="lstLabels"
                                                value={filter?.label}
                                                label="Labels"
                                                onChange={handleLabelChange}
                                                multiple={true}
                                                renderValue={(selected) => selected.join(', ')}
                                                >
                                                    {allLabels?.values?.map((item) => (
                                                        <MenuItem key={uuidv4()} value={item?.title}>
                                                            <Checkbox checked={filter?.label?.indexOf(item?.title) > -1} />
                                                            <ListItemText primary={item.title} />
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={2}>
                                        <FormControl fullWidth>
                                            <InputLabel>Country</InputLabel>
                                            <Select
                                                labelId="lblCountry"
                                                id="lstCountry"
                                                value={filter?.country}
                                                label="Country"
                                                onChange={handleCountryChange}
                                                multiple={false}
                                                renderValue={(selected) => selected.join(', ')}
                                                >
                                                    {allCountries?.values?.map((item) => (
                                                        <MenuItem key={uuidv4()} value={item?.title}>
                                                            <Checkbox checked={filter?.country?.indexOf(item?.title) > -1} />
                                                            <ListItemText primary={item.title} />
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={2}>
                                        <FormControl fullWidth>
                                            <InputLabel>Year</InputLabel>
                                            {/* <Select
                                                labelId="lblYear"
                                                id="lstYear"
                                                value={year}
                                                label="Year"
                                                onChange={(e: SelectChangeEvent) => setYear(e.target.value)}
                                                >
                                                    <MenuItem value=""><em>Any</em></MenuItem>
                                                    {allYears?.values?.map(function(item:any) {
                                                        return (
                                                            <MenuItem key={uuidv4()} value={item?.value}>{item?.title} ({item?.count})</MenuItem>
                                                        )
                                                    })}
                                            </Select> */}
                                            <Select
                                                labelId="lblYear"
                                                id="lstYear"
                                                value={filter?.year}
                                                label="Year"
                                                onChange={handleYearChange}
                                                multiple={false}
                                                renderValue={(selected) => selected.join(', ')}
                                                >
                                                    {allYears?.values?.map((item) => (
                                                        <MenuItem key={uuidv4()} value={item?.title}>
                                                            <Checkbox checked={filter?.year?.indexOf(item?.title) > -1} />
                                                            <ListItemText primary={item.title} />
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={6} md={2} mt={1}>
                                        <Button fullWidth variant='outlined' color='secondary' size='large'
                                            onClick={resetFilters}
                                        >
                                            Reset
                                        </Button>
                                    </Grid>
                

                                    {/* <Grid item xs={12} sm={12} md={12} lg={4}>
                                        <Button variant="contained" size="large" 
                                            sx={{ 
                                                marginRight: '2rem', 
                                                marginBottom: '0.5rem',
                                                width: { xs: '100%', md: 'auto' }
                                            }}
                                            onClick={handleSearch} title='Search user collection'
                                        >
                                            Search Collection
                                        </Button>

                                        <Button variant="outlined" size="large" 
                                            sx={{  
                                                marginBottom: '0.5rem',
                                                width: { xs: '100%', md: 'auto' }
                                            }}
                                            onClick={clearSearch} title='Clear search criteria'
                                        >
                                            Clear
                                        </Button>
                                    </Grid> */}

                                </Grid>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>

            <Grid container mb={3} mt={3}>
                <Grid item xs={12}>
                    Showing {releases?.length} Versions
                </Grid>
            </Grid>

            <Grid container pb={2} pt={2} sx={{
                borderTop: '1px solid #a1a1a1'
            }}>
                <Grid item xs={3} md={1}></Grid>
                <Grid item xs={6} md={3}><strong>Title / Format</strong></Grid>
                <Grid item xs={3} md={2}><strong>Label / Cat #</strong></Grid>
                <Grid item xs={3} md={2}><strong>Country</strong></Grid>
                <Grid item xs={3} md={1}><strong>Year</strong></Grid>
            </Grid>
            { !data && 
                <>
                    <Grid container mb={2}>
                        <Grid item xs={3} md={1}>
                            <Skeleton variant="rectangular" width={75} height={75} />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Skeleton variant="rectangular" width={100} height={22} />
                            <br />
                            <Skeleton variant="rectangular" width={75} height={22} />
                        </Grid>
                        <Grid item xs={2} md={2}>
                            <Skeleton variant="rectangular" width={50} height={22} />
                        </Grid>
                        <Grid item xs={2} md={2}>
                            <Skeleton variant="rectangular" width={50} height={22} />
                        </Grid>
                        <Grid item xs={2} md={1}>
                            <Skeleton variant="rectangular" width={50} height={22} />
                        </Grid>
                        <Grid item xs={2} md={3}>
                            <Skeleton variant="rectangular" width={'100%'} height={36.5} />
                        </Grid>
                    </Grid>
                    <Grid container mb={2}>
                        <Grid item xs={3} md={1}>
                            <Skeleton variant="rectangular" width={75} height={75} />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Skeleton variant="rectangular" width={100} height={22} />
                            <br />
                            <Skeleton variant="rectangular" width={75} height={22} />
                        </Grid>
                        <Grid item xs={2} md={2}>
                            <Skeleton variant="rectangular" width={50} height={22} />
                        </Grid>
                        <Grid item xs={2} md={2}>
                            <Skeleton variant="rectangular" width={50} height={22} />
                        </Grid>
                        <Grid item xs={2} md={1}>
                            <Skeleton variant="rectangular" width={50} height={22} />
                        </Grid>
                        <Grid item xs={2} md={3}>
                            <Skeleton variant="rectangular" width={'100%'} height={36.5} />
                        </Grid>
                    </Grid>
                </>
            }
            {releases?.map(function(item:any) {
                return (
                    <Grid container key={uuidv4()} pb={2} pt={2} sx={{
                        borderTop: '1px solid #a1a1a1'
                    }}>
                        <Grid item xs={3} md={1}>
                            <AlbumImages id={item?.id} thumb={item?.thumb} title={item?.title} />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Link component={RouterLink} to={`../release/${item?.id}`}>
                                {item?.title}
                            </Link>
                            <br />
                            <Typography component={'span'} variant='caption'>{item?.format}</Typography>
                            {/* <Typography component={'span'} variant='caption'>{flattenFormatsReleases(item?.format)}</Typography> */}
                            {/* <Link component={RouterLink} to={`../artist/${id}/master/${item?.id}`}>
                                {item?.title?.split(' - ')[1]}
                            </Link> */}
                        </Grid>
                        <Grid item xs={2} md={2}>
                            {item?.label}<br />
                            {item?.catno}
                        </Grid>
                        <Grid item xs={2} md={2}>
                            {item?.country}
                        </Grid>
                        <Grid item xs={2} md={1}>
                            {item?.released}
                        </Grid>
                        <Grid item xs={2} md={3}>
                            <CollectionMenu item={item} />
                        </Grid>
                    </Grid>
                )
            })}
            <CollectionPagination page={page} setPage={setPage} pagination={data?.getDiscogsMaster?.pagination} />

        </>
    )
}

export default DiscogsMaster;