import React, { ReactElement, useEffect } from 'react';
import { DEFAULT_REDIRECT_PATH } from '../constants';
import { useAuth0 } from '@auth0/auth0-react';
import { Outlet, useNavigate } from 'react-router-dom';

interface IProtectedRoute {
    requiredRole: string;
    redirectPath?: string;
    children?: ReactElement | ReactElement[];
}

const ProtectedRoute:React.FunctionComponent<IProtectedRoute> = ({ 
    requiredRole, redirectPath = DEFAULT_REDIRECT_PATH, children
}) => {

    if (!redirectPath)
        redirectPath = '/noaccess';

    const { user, isAuthenticated, isLoading } = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {

        if (!isLoading && isAuthenticated) {

            // set the stage
            let redirect = true;
            let userRoles: string[] | undefined = [];

            // build the role key that is required in the auth roles
            const roleKey = `${process.env.REACT_APP_AUTH0_AUDIENCE}/roles`;

            // if the user object exists, get the roles
            if (user)
                userRoles = user[roleKey];

            // determine if there are matching roles
            if (userRoles) {
                userRoles.forEach(role => {
                    if (role === requiredRole)
                        redirect = false;
                })
            }

            // redirect if user doesn't have the role
            if (redirect)
                navigate(redirectPath);

        } else if (!isLoading && !isAuthenticated) {
            navigate(redirectPath);
        }

    }, [isAuthenticated, isLoading, user, navigate, redirectPath, requiredRole])

    return (
        <>{children ? children : <Outlet />}</>
    )

};

export default ProtectedRoute;