import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { AlertColor } from "@mui/material";

interface GlobalAlertState {
    severity: AlertColor;
    message: string;
    show: boolean;
}

const initialState:GlobalAlertState = {
    severity: "error",
    message: "",
    show: false
}

const globalAlertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        setAlert: (state, action:PayloadAction<GlobalAlertState>) => {
            state.severity = action?.payload?.severity;
            state.message = action?.payload?.message;
            state.show = action?.payload?.show;
        }
    }
});

export const { setAlert } = globalAlertSlice.actions;

export const getAlertState = (state: RootState) => state.globalAlert;

export default globalAlertSlice.reducer;