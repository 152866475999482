import { combineReducers, configureStore } from '@reduxjs/toolkit';

import userReducer from './sliceUser';
import discogsReducer from './sliceDiscogs';
import notificationsReducer from './sliceNotifications';
import globalLoadingReducer from './sliceLoading';
import globalAlertReducer from './sliceAlert';

import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

// const middlewares = [];

// if (process.env.NODE_ENV === 'development') {
//     middlewares.push(logger);
// }

//export const store = createStore(rootReducer, applyMiddleware(...middlewares));

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user','discogs','notifications','globalLoading'],
    blacklist: ['globalAlert']
};

const rootReducer = combineReducers({ 
    user: userReducer,
    discogs: discogsReducer,
    notifications: notificationsReducer,
    globalLoading: globalLoadingReducer,
    globalAlert: globalAlertReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export default store;
export const persistor = persistStore(store);

//export const persistor = persistStore(store);

//export default {store, persistor};

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch