import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

import './DiscogsProfile.scss'; 
import secureLocalStorage from 'react-secure-storage';
import { STORAGE_KEY_TOKEN, STORAGE_KEY_SECRET, STORAGE_KEY_VERIFIER } from '../../constants';
import { setCurrentUser } from '../../redux/sliceUser';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Grid, Snackbar } from '@mui/material';
import { clearDiscogsInfo } from '../../redux/sliceDiscogs';

const DiscogsProfile:React.FunctionComponent =() => {

    const user = useAppSelector((state) => state?.user?.value);
    const dispatch = useAppDispatch();
    const [snackOpen, setSnackOpen] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        //console.log("token", secureLocalStorage.getItem(STORAGE_KEY_TOKEN)?.toString()!);
        //console.log("secret", secureLocalStorage.getItem(STORAGE_KEY_SECRET)?.toString()!);
        //console.log("verifier", secureLocalStorage.getItem(STORAGE_KEY_VERIFIER)?.toString()!)
    }, [])

    const handleSignout = () => {
        
        secureLocalStorage.removeItem(STORAGE_KEY_TOKEN);
        secureLocalStorage.removeItem(STORAGE_KEY_SECRET);
        secureLocalStorage.removeItem(STORAGE_KEY_VERIFIER);

        dispatch(setCurrentUser({}));
        dispatch(clearDiscogsInfo())
        setSnackOpen(true);

        navigate('../../discogs');
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackOpen(false);
    };

    return (
        <div>

            <Snackbar open={snackOpen} autoHideDuration={6000} 
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Credentials and local storage deleted!
                </Alert>
            </Snackbar>

            <Grid container className='page-header-row' mb={3}>
                <Grid item xs={12}><h1>Discogs Profile</h1></Grid>
            </Grid>
            <Grid container className='details-row'>
                <Grid item xs={12} sm={12} md={2} lg={2} className='col-lg-2 col-md-2 col-sm-12 col-12'><strong>Username</strong></Grid>
                <Grid item xs={12} sm={12} md={10} lg={10} className='col-lg-10 col-md-10 col-sm-12 col-12'>{user?.discogsUsername}</Grid>
            </Grid>
            <Grid container className='details-row'>
                <Grid item xs={12} sm={12} md={2} lg={2}  className='col-lg-2 col-md-2 col-sm-12 col-12'><strong>Url</strong></Grid>
                <Grid item xs={12} sm={12} md={10} lg={10} className='col-lg-10 col-md-10 col-sm-12 col-12'>
                    <a href={`https://www.discogs.com/user/${user?.discogsUsername}`} target='_blank' rel='noreferrer'>{`https://www.discogs.com/user/${user?.discogsUsername}`} </a>
                </Grid>
            </Grid>
            <Grid container className='details-row'>
                <Grid item xs={12} sm={12} md={2} lg={2}  className='col-lg-2 col-md-2 col-sm-12 col-12'><strong>Avatar</strong></Grid>
                <Grid item xs={12} sm={12} md={10} lg={10} className='col-lg-10 col-md-10 col-sm-12 col-12'>
                    <img className='avatar-image' src={user?.discogsUserAvatar} alt='Your discogs avatar' />
                </Grid>
            </Grid>
            <Grid container className='details-row'>
                <Grid item xs={12} sm={12} md={2} lg={2}  className='col-lg-2 col-md-2 col-sm-12 col-12'><strong>Forget my credentials</strong></Grid>
                <Grid item xs={12} sm={12} md={10} lg={10} className='col-lg-10 col-md-10 col-sm-12 col-12'>
                    <Button variant='contained' color="error" onClick={handleSignout}>Sign out of Discogs</Button>
                    
                </Grid>
            </Grid>
            <Grid container className='details-row'>
                <Grid item xs={12} sm={12} md={2} lg={2}></Grid>
                <Grid item xs={12} sm={12} md={10} lg={10} className='col-lg-10 col-md-10 col-sm-12 col-12'>
                    <em>If you signout of Discogs you will need to reauthenticate. If you ever need to revoke access
                        from this, or any other application, it can be done here: <a href='https://www.discogs.com/settings/applications' target='_blank' rel='noreferrer'>https://www.discogs.com/settings/applications</a>
                    </em>
                </Grid>
            </Grid>
        </div>
    )
}

export default DiscogsProfile;