import React, { useEffect, useState } from 'react';

import './CollectionSearch.scss';
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Select, TextField, Typography } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { v4 as uuidv4 } from 'uuid';
import { gqlGetFolders } from '../../gql';
import { gql, useQuery } from '@apollo/client';

interface ICollectionSearch {
    sort: string;
    setSort: React.Dispatch<React.SetStateAction<string>>;
    order: string;
    setOrder: React.Dispatch<React.SetStateAction<string>>;
    setSearch: React.Dispatch<React.SetStateAction<string>>;
    folder: string;
    setFolder: React.Dispatch<React.SetStateAction<string>>;
    username: string;
}

const CollectionSearch:React.FunctionComponent<ICollectionSearch> = ({ 
    sort, setSort, order, setOrder, setSearch, folder, setFolder, username
}) => {
    const [tmpSearch, setTmpSearch] = useState("");
    const [folders, setFolders] = useState<any>([{ id: 0, count: 0, name: "All"}]);
    
    const sortOrderOptions = [
        { id: 10, key: "asc", value: "Ascending" },
        { id: 20, key: "desc", value: "Descending" }
    ];

    const sortByOptions = [
        { id: 10, key: "added", value: "Date Added" },
        { id: 20, key: "artist", value: "Artist Name" },
        { id: 30, key: "title", value: "Album Title" },
    ];

    const handleSortBy = (e: SelectChangeEvent) => {
        setSort(e.target.value);
    }

    const handleSortOrder = (e: SelectChangeEvent) => {
        setOrder(e.target.value);
    }

    const handleSearchChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        setTmpSearch(e.currentTarget.value)
    }

    const handleSearchKeyDown = (e:React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            setSearch(tmpSearch);
        }
    }

    const handleSearch = async () => {
        setSearch(tmpSearch);
    }

    const clearSearch = async () => {
        setSearch('');
        setTmpSearch('');
    }

    const handleFolder = async (e: SelectChangeEvent) => {
        setSearch('');
        setTmpSearch('');
        setFolder(e.target.value)
    }

    const foldersQuery = gqlGetFolders(username);
    const { loading:foldersLoading, error:foldersError, data:foldersData} = useQuery(gql`${foldersQuery.query}`, { variables: foldersQuery.variables });

    useEffect(() => {

        if (foldersData)
            setFolders(foldersData?.getFolders);

        if (foldersError)
            console.error(foldersError);

    }, [foldersData, foldersError])
    
    return (
        <>
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="search-tools"
                                id="search-tools"
                                >
                                <Typography>Sort and Search</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography component={'span'}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={2} lg={2}>
                                            <FormControl fullWidth>
                                                <InputLabel>Sort By</InputLabel>
                                                <Select
                                                    labelId="lblSortBy"
                                                    id="lstSortBy"
                                                    value={sort}
                                                    label="Sort By"
                                                    onChange={handleSortBy}
                                                    >
                                                        {sortByOptions.map(function(item:any) {
                                                            return (
                                                                <MenuItem key={uuidv4()} value={item.key}>{item.value}</MenuItem>
                                                            )
                                                        })}
                                                </Select>
                                            </FormControl>         
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={2} lg={2}>
                                            <FormControl fullWidth>
                                                <InputLabel>Sort Order</InputLabel>
                                                <Select
                                                    labelId="lblSortOrder"
                                                    id="lstSortOrder"
                                                    value={order}
                                                    label="Sort Order"
                                                    onChange={handleSortOrder}
                                                    >
                                                        {sortOrderOptions.map(function(item:any) {
                                                            return (
                                                                <MenuItem key={uuidv4()} value={item.key}>{item.value}</MenuItem>
                                                            )
                                                        })}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={2} lg={2}>
                                            <FormControl fullWidth>
                                                <TextField id="outlined-basic" label="Search Terms" variant="outlined"
                                                    type="search"
                                                    value={tmpSearch} 
                                                    onChange={handleSearchChange}
                                                    onKeyDown={handleSearchKeyDown}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={4}>
                                            <Button variant="contained" size="large" 
                                                sx={{ 
                                                    marginRight: '2rem', 
                                                    marginBottom: '0.5rem',
                                                    width: { xs: '100%', md: 'auto' }
                                                }}
                                                onClick={handleSearch} title='Search user collection'
                                            >
                                                Search Collection
                                            </Button>

                                            <Button variant="outlined" size="large" 
                                                sx={{  
                                                    marginBottom: '0.5rem',
                                                    width: { xs: '100%', md: 'auto' }
                                                }}
                                                onClick={clearSearch} title='Clear search criteria'
                                            >
                                                Clear
                                            </Button>
                                        </Grid>

                                    </Grid>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>

                <div className='spacer-row'><div></div></div>

            </div>

            <div className='user-folders'>
                <Grid container spacing={2} mb={3}>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                        <FormControl fullWidth>
                            <InputLabel>Folder</InputLabel>
                            <Select
                                labelId="lblFolders"
                                id="lstFolders"
                                value={folder}
                                label="Folder"
                                onChange={handleFolder}
                                >
                                    {folders?.map(function(item:any) {
                                        return (
                                            <MenuItem key={item.id} value={item.id}>{item.name} ({item.count})</MenuItem>
                                        )
                                    })}
                            </Select>
                        </FormControl>   
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2} mb={3} sx={{ display: { xs: 'none', md: 'inline' } }}>
                        { foldersLoading && <CircularProgress color='secondary' /> } 
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2} mb={3} sx={{ display: { xs: 'inline', md: 'none' } }}>
                        { foldersLoading && <LinearProgress color='primary' /> } 
                    </Grid>
                </Grid>
            </div>
        </>
    )
};

export default CollectionSearch;