import React, { useEffect } from 'react';

import './AddToQueueButton.scss';
import { gqlAddToQueue } from '../../gql';
import { gql, useMutation } from '@apollo/client';
import { ICustomSnackState } from '../custom-snack-bar/CustomSnackBar';
import { LoadingButton } from '@mui/lab';
import QueueIcon from '@mui/icons-material/Queue';
import { useAppDispatch } from '../../redux/hooks';
import { setLoading } from '../../redux/sliceLoading';

interface IAddToQueueButton {
    id: number;
    setCustomSnackState: React.Dispatch<React.SetStateAction<ICustomSnackState>>;
}

const AddToQueueButton:React.FunctionComponent<IAddToQueueButton> = ({ id, setCustomSnackState }) => {

    const addQuery = gqlAddToQueue(id);
    const [addToQueue, { loading, error, data}] = useMutation(gql`${addQuery.query}`, {
        variables: addQuery.variables,
        fetchPolicy: 'no-cache'
    });

    // use redux toolkit dispatch
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setLoading(loading));
    }, [loading, dispatch])

    useEffect(() => {

        if (data?.addToQueue?.success === true) {
            setCustomSnackState({ hideDuration: 5000, open: true, severity: "success", message: "Queue entry added!"});
            console.log(data?.addToQueue);
        } else if (data?.addToQueue?.success === false) {
            setCustomSnackState({ hideDuration: 5000, open: true, severity: "error", message: "Queue entry not added!"});
            console.log(data?.addToQueue);
        }

        if (error) {
            setCustomSnackState({ hideDuration: 5000, open: true, severity: "error", message: "There was an error adding this album to your queue"});
            console.error(error);
        }

    // eslint-disable-next-line
    }, [error, data]);

    const handleAddToQueue = () => {
        addToQueue();
    }
    
    return (
        <>
            <LoadingButton variant='contained' startIcon={<QueueIcon />}
                loading={loading}
                color="secondary"
                onClick={handleAddToQueue}
                // sx={{ marginRight: '0.5rem', display: { xs: 'none', md: 'inline' } }}
                sx={{ width: { xs: '70%', md: 'auto' } }}
            >
                Queue
            </LoadingButton>
        </>
    )
};

export default AddToQueueButton;