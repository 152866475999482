import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import './UserTimeline.scss';
import { useQuery, gql } from "@apollo/client";
import { gqlUserListens } from "../../gql";

import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";

import TimelineIcon from '@mui/icons-material/Timeline';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { determineListeningTime, secondsToHms } from "../../utils/dates";
import TimelineLayout from "../TimelineLayout/TimelineLayout";
import { useAppDispatch } from "../../redux/hooks";
import { setLoading } from "../../redux/sliceLoading";

dayjs.extend(utc);

const UserTimeline:React.FunctionComponent = () => {
    const params = useParams();
    const [timelineData, setTimelineData] = useState<any>([]);
    const [refetchData, setRefetchData] = useState(false);

    let username:string = '';
    if (params?.id) {
        username = params?.id;
    }

    const [dateRange, setDateRange] = useState<any>({
        start: dayjs().startOf("week").utc().unix(),
        end: dayjs().endOf("week").utc().unix()
    });

    const query = gqlUserListens(dateRange, username);
    const {loading, error, data, refetch} = useQuery(gql`${query.query}`, {
        variables: query.variables,
        fetchPolicy: 'cache-and-network'
    });

    useEffect(() => {

        if (refetchData === true) {
            refetch();
            setRefetchData(false);
        }

    }, [refetchData, refetch])

    const topRef = useRef<null | HTMLDivElement>(null);

    const [listeningTime, setListeningTime] = useState('');

    const headerUser = username !== '' ? username + "'s" : "My";

    useEffect(() => {

        if (data) {

            setTimelineData(data?.getListens);

            const total = determineListeningTime(data?.getListens);
            setListeningTime(secondsToHms(total));
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);
    
    // timeline options
    const timelineOptions = [
        { id: 100, value: "thisweek", label: "This Week" },
        { id: 200, value: "lastweek", label: "Last Week" },
        { id: 300, value: "thismonth", label: "This Month" },
        { id: 400, value: "lastmonth", label: "Last Month" },
        { id: 500, value: "thisyear", label: "This Year" },
        { id: 600, value: "lastyear", label: "Last Year" },
    ];
    const [dateRangeOption, setDateRangeOption] = useState(timelineOptions[0].value);

    const handleDateChange = (e: SelectChangeEvent) => {
        switch (e.target.value) {
            case "thisweek":
                setDateRange({
                    start: dayjs().startOf("week").utc().unix(),
                    end: dayjs().endOf("week").utc().unix()
                });
                setDateRangeOption("thisweek");
                break;
            case "lastweek":
                setDateRange({
                    start: dayjs().subtract(1, "week").startOf("week").utc().unix(),
                    end: dayjs().subtract(1, "week").endOf("week").utc().unix()
                });
                setDateRangeOption("lastweek");
                break;
            case "thismonth":
                setDateRange({
                    start: dayjs().startOf("month").utc().unix(),
                    end: dayjs().endOf("month").utc().unix()
                });
                setDateRangeOption("thismonth");
                break;
            case "lastmonth":
                setDateRange({
                    start: dayjs().subtract(1, "month").startOf("month").utc().unix(),
                    end: dayjs().subtract(1, "month").endOf("month").utc().unix()
                });
                setDateRangeOption("lastmonth");
                break;
            case "thisyear":
                setDateRange({
                    start: dayjs().startOf("year").utc().unix(),
                    end: dayjs().endOf("year").utc().unix()
                });
                setDateRangeOption("thisyear");
                break;
            case "lastyear": 
                setDateRange({
                    start: dayjs().subtract(1, "year").startOf("year").utc().unix(),
                    end: dayjs().subtract(1, "year").endOf("year").utc().unix()
                });
                setDateRangeOption("lastyear");
                break;
            default:
                break;
        }
    }

    // use redux toolkit dispatch
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setLoading(loading));
    }, [loading, dispatch])
    
    return (
        <div>
            <div ref={topRef}></div>
            <Grid container className='page-header-row' mb={3}>
                <Grid item xs={12}><h1>{headerUser} Timeline <TimelineIcon fontSize="large" /></h1></Grid>
            </Grid>

            <Grid container mb={3}>

                <Grid item xs={12} sm={12} md={2} lg={2} mb={3}
                    sx={{
                        marginRight: { sm: '0rem', md: '3rem'}
                    }}
                    >
                    <FormControl fullWidth>
                        <InputLabel>Date range</InputLabel>
                        <Select
                            labelId="lblDateRange"
                            id="lstDateRange"
                            value={dateRangeOption}
                            label="Date Range"
                            onChange={handleDateChange}
                            >
                                {timelineOptions.map(function(item:any) {
                                    return (
                                        <MenuItem key={item.id} value={item.value}>{item.label}</MenuItem>
                                    )
                                })}
                        </Select>
                    </FormControl>
                </Grid>

                { data?.getListens?.length > 0 &&
                    <Grid item xs={12} sm={12} md={8} lg={8}>
                        <Typography
                            sx={{
                                marginTop: { sm: '0rem', md: '0rem' },
                                textAlign: { xs: 'center', sm: 'center', md: 'left' }
                            }}
                        >
                            Listening time: { data?.getListens?.length > 0 && listeningTime }<br />
                            Total sessions: { data?.getListens?.length }<br />
                            Number of albums: { Array.from(new Set(data?.getListens?.map((item: { releaseId: number; }) => item.releaseId))).length }
                        </Typography>
                    </Grid>
                }

                { data?.getListens?.length === 0 && 
                    <Grid item xs={12} sm={12} md={8} lg={8}>
                        <Typography
                            sx={{
                                marginTop: { sm: '0rem', md: '1rem' },
                                textAlign: { xs: 'center', sm: 'center', md: 'left' }
                            }}
                        >
                            { data?.getListens?.length === 0 && `${(username ? (username + " has") : "You have")} not listened to anything during this period` }
                        </Typography>
                    </Grid>
                }

            </Grid>

            <TimelineLayout 
                timelineData={timelineData} 
                username={username}
                setTimelineData={setTimelineData}
                setRefetchData={setRefetchData} />

        </div>
    )
}

export default UserTimeline;