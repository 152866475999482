import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";

interface UserState {
    value:any;
}

const initialState:UserState = {
    value: { },
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setCurrentUser: (state, action:PayloadAction<any>) => {
            state.value = action.payload;
        },
        setUserFriends: (state, action:PayloadAction<any>) => {
            state.value = {
                ...state.value,
                friends: action.payload
            }
        }
    }
})

export const { setCurrentUser, setUserFriends } = userSlice.actions;

export const getUser = (state: RootState) => state.user.value;

export default userSlice.reducer;