import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/home/Home';
import Discogs from './components/discogs/Discogs';
import Collection from './components/collection/Collection';
import Layout from './components/layout/Layout';
import DiscogsIdentity from './components/discogs/DiscogsIdentity';

import './App.scss';
import DiscogsProfile from './components/discogs/DiscogsProfile';
import UserTimeline from './components/user-timeline/UserTimeline';
import TimelineAdd from './components/TimelineAdd/TimelineAdd';
import Friends from './components/friends/Friends';
import PrivacyPolicy from './components/policies/PrivacyPolicy';
import TimelineEdit from './components/TimelineEdit/TimelineEdit';
import TimelineDelete from './components/TimelineDelete/TimelineDelete';
import TimelineRelease from './components/TimelineRelease/TimelineRelease';
import Notifications from './components/Notifications/Notifications';
import { NotificationViewType } from './enums/NotificationViewType';
import Suggest from './suggest/Suggest';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import UserQueue from './components/UserQueue/UserQueue';
import ContactUs from './components/ContactUs/ContactUs';
import AdminContactMessages from './components/Admin/AdminContactMessages/AdminContactMessages';
import ProtectedRoute from './ProtectedRoute/ProtectedRoute';
import { AUTH_ROLES } from './constants';
import AdminLanding from './components/Admin/AdminLanding/AdminLanding';
import Logout from './components/Logout/Logout';
import SearchDiscogs from './pages/Discogs/SearchDiscogs/SearchDiscogs';
import DiscogsArtist from './pages/Discogs/Artist/DiscogsArtist';
import DiscogsMaster from './pages/Discogs/Master/DiscogsMaster';
import DiscogsRelease from './pages/Discogs/DiscogsRelease/DiscogsRelease';

// Augment the palette to include an ochre color
declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary'];
  }

  interface PaletteOptions {
    tertiary?: PaletteOptions['primary'];
  }
}

// Update the Button's color options to include an ochre option
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#278421'
    },
    secondary: {
      main: '#7d2184'
    },
    tertiary: {
      main: '#21844c',
      dark: '#156438',
      light: '#31a864',
      contrastText: '#fff'
    }
  },
});

const App: React.FunctionComponent = () => { 

  return (
    <ThemeProvider theme={theme}>
      <div className="App">

          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="discogs" element={<Discogs />} />
                <Route path="discogs/identity" element={<DiscogsIdentity />} />
                <Route path="discogs/profile" element={<DiscogsProfile />} />
                <Route path="collection" element={<Collection />} />
                <Route path="timeline" element={<UserTimeline />} />
                <Route path="timeline/add" element={<TimelineAdd />} /> 
                <Route path="timeline/add/:id" element={<TimelineAdd />} /> 
                <Route path="timeline/edit" element={<TimelineEdit />} />
                <Route path="timeline/delete" element={<TimelineDelete />} />
                <Route path="timeline/user/:id" element={<UserTimeline />} />
                <Route path="timeline/user/:id/:releaseId" element={<TimelineRelease />} />
                <Route path="friends" element={<Friends />} />
                <Route path="suggest" element={<Suggest />} />
                <Route path="policies/privacy" element={<PrivacyPolicy />} />
                <Route path="notifications" element={<Notifications viewType={NotificationViewType.FULLSCREEN} getUnread={null} />} />
                <Route path="queue" element={<UserQueue />} />
                <Route path="contact" element={<ContactUs />} />
                <Route path="logout" element={<Logout />} />
                <Route path="discogs/search" element={<SearchDiscogs />} />
                <Route path="artist/:id" element={<DiscogsArtist />} />
                <Route path="master/:id" element={<DiscogsMaster />} />
                <Route path="release/:id" element={<DiscogsRelease />} />

                <Route element={<ProtectedRoute requiredRole={AUTH_ROLES.ARAdmin} />}> 
                  <Route path="/admin" element={<AdminLanding />} />
                  <Route element={<ProtectedRoute requiredRole={AUTH_ROLES.ARAdminViewMessages} />}> 
                    <Route path="/admin/contactmessages" element={<AdminContactMessages />} />
                  </Route>
                </Route>

              </Route>
            </Routes>
          </BrowserRouter>

      </div>
    </ThemeProvider>
  );
}

export default App;
