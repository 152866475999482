import React, { useEffect } from 'react';

import RemoveIcon from '@mui/icons-material/Remove';
import { ICustomSnackState } from '../custom-snack-bar/CustomSnackBar';
import { gqlDeleteFromQueue } from '../../gql';
import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';

interface IRemoveFromQueueButton {
    id: string;
    setData: React.Dispatch<React.SetStateAction<any[]>> | null;
    setSnackState: React.Dispatch<React.SetStateAction<ICustomSnackState>> | null;
}

const RemoveFromQueueButton:React.FunctionComponent<IRemoveFromQueueButton> = ({ id, setData, setSnackState }) => {

    const deleteQuery = gqlDeleteFromQueue(id);
    const [deleteFromQueue, { loading, error, data}] = useMutation(gql`${deleteQuery.query}`, {
        variables: deleteQuery.variables,
        fetchPolicy: 'network-only'
    })

    const handleRemoveFromQueue = () => {
        deleteFromQueue();
    }

    useEffect(() => {

        if (data) {
            if (setSnackState)
                setSnackState({ open: true, severity: "success", message: "Queue entry deleted!", hideDuration: 5000});

            if (setData)
                setData(data?.deleteFromQueue);
        }

        if (error) {
            console.error(error);

            if (setSnackState)
                setSnackState({ hideDuration: 5000, open: true, severity: "error", message: "There was an error removing this album from your queue"});
        }
    
    // eslint-disable-next-line
    }, [data, error])

    return (
        <>
            <LoadingButton variant='outlined' color='secondary' startIcon={<RemoveIcon />}
                loading={loading}
                onClick={handleRemoveFromQueue} 
                sx={{ marginRight: '0.5rem', width: { xs: '50%', md: 'auto' } }}>
                Remove
            </LoadingButton>
        </>
    )
}

export default RemoveFromQueueButton;