import React, { useEffect } from 'react';

// import { Link } from 'react-router-dom';

import './SearchDiscogs.scss';
import ISearchDiscogs from '../../../interfaces/ISearchDiscogs';
import { gqlSearchDiscogs } from '../../../gql';

import { gql, useLazyQuery } from '@apollo/client';

import { FormControl, Grid, Button, TextField
} 
from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import { v4 as uuidv4 } from 'uuid'; 

import { useAppDispatch } from '../../../redux/hooks';
import { setLoading } from '../../../redux/sliceLoading';

import SearchResultsRow from '../../../components/SearchResultsRow/SearchResultsRow';

import  { useNavigate, useSearchParams } from 'react-router-dom'
import { useStateWithCallbackLazy } from 'use-state-with-callback';

const SearchDiscogs:React.FunctionComponent = () => {
    const topRef = React.useRef<null | HTMLDivElement>(null);
    const nav = useNavigate();
    const [queryParams, setQueryParams] = useSearchParams();

    const [page, setPage] = React.useState(1);
    //const [selectedTab, setSelectedTab] = React.useState('1');
    const [allData, setAllData] = React.useState<any>();

    // initial search state
    const initialSearchState = {
        perPage: 50,
        sort: "year",
        order: "desc",
        searchTerm: queryParams.get("searchTerm") !== null ? queryParams.get("searchTerm")! : "",
        searching: "query",
        format: "all"
    };
    //console.log("initialSearchState", initialSearchState);

    const [searchParams, setSearchParams] = useStateWithCallbackLazy<ISearchDiscogs>(initialSearchState);
    const [tmpSearchParams, setTmpSearchParams] = React.useState<ISearchDiscogs>(initialSearchState);

    // our graphql query
    const searchQuery = gqlSearchDiscogs(page, searchParams);
    const [search, { loading, error, data}] = useLazyQuery(gql`${searchQuery.query}`, {
        variables: searchQuery?.variables,
        fetchPolicy: 'network-only'
    });

    useEffect(() => {

        if (queryParams.get("searchTerm") !== null) {
            setSearchParams({...searchParams, searchTerm: queryParams.get("searchTerm")!}, () => { search(); })
        }

    // eslint-disable-next-line
    }, [queryParams, search])

    // data returned from graphql
    React.useEffect(() => {

        if (data?.searchDiscogs) {
            setAllData(data?.searchDiscogs);
        }

        if (error) {
            console.error(error);
        }

    }, [data, error]);

    // const searchOptions = [
    //     { id: 10, key: "query", value: "Everything" },
    //     { id: 20, key: "release_title", value: "Releases" },
    //     { id: 30, key: "artist", value: "Artists" },
    // ];

    // const formatOptions = [
    //     { id: 10, key: "all", value: "All Formats" },
    //     { id: 20, key: "Vinyl", value: "Vinyl" },
    //     { id: 30, key: "CD", value: "CD" },
    //     { id: 40, key: "Cassette", value: "Cassette" },
    // ];

    // const sortByOptions = [
    //     { id: 20, key: "artist", value: "Artist Name" },
    //     { id: 30, key: "year", value: "Year" },
    //     { id: 40, key: "catno", value: "Catalogue Number"},
    //     { id: 50, key: "title", value: "Title" }
    // ];

    // const sortOrderOptions = [
    //     { id: 10, key: "asc", value: "Ascending" },
    //     { id: 20, key: "desc", value: "Descending" }
    // ];

    

    // clear all search data
    
    const clearSearch = () => {
        setTmpSearchParams({...initialSearchState, searchTerm: ''});
        setPage(1);
        setAllData([]);
        //queryParams.delete("searchTerm");
        nav('');
        
    }

    // search discogs button handler
    const handleSearch = () => {
        if (tmpSearchParams?.searchTerm !== '') {
            //setSearchParams({...searchParams, searchTerm: tmpSearchParams?.searchTerm}, () => { search(); });
            setQueryParams({...queryParams, searchTerm: tmpSearchParams?.searchTerm})
        }
    }

    // search when the enter key is pressed
    const handleSearchKeyDown = (e:React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    }

    // use redux toolkit dispatch
    const dispatch = useAppDispatch();
    React.useEffect(() => {
        dispatch(setLoading(loading));
    }, [loading, dispatch])

    return (
        <div>
            {/* <button onClick={runSearch}>Search</button> */}
            <div ref={topRef}></div>
            <Grid container className='page-header-row' mb={3}>
                <Grid item xs={12}><h1>Search Discogs <SearchIcon fontSize='large' /></h1></Grid>
            </Grid>

            <Grid container mb={3}>
                <Grid item xs={12} md={4}>

                    <FormControl fullWidth>
                        <TextField id="outlined-basic" label="Search Terms" variant="outlined"
                            type="search"
                            value={tmpSearchParams?.searchTerm} 
                            onChange={(e) => setTmpSearchParams({...tmpSearchParams, searchTerm: e.currentTarget.value })}
                            onKeyDown={handleSearchKeyDown}
                        />
                    </FormControl>
  
                </Grid>

                {/* <Grid item xs={12} md={4} 
                    sx={{ 
                        paddingLeft: { xs: '0rem', md: '2rem' },
                        paddingTop: { xs: '0.75rem', md: '0rem' },
                        paddingBottom: { xs: '0.75rem', md: '0rem' }
                    }}>
                    <FormControl fullWidth>
                        <InputLabel>Search</InputLabel>
                        <Select
                            labelId="lblSearchOn"
                            id="lstSearchOn"
                            value={tmpSearchParams.searching}
                            label="Search"
                            onChange={(e: SelectChangeEvent) => setTmpSearchParams({...tmpSearchParams, searching: e.target.value})}
                            >
                                {searchOptions.map(function(item:any) {
                                    return (
                                        <MenuItem key={uuidv4()} value={item.key}>{item.value}</MenuItem>
                                    )
                                })}
                        </Select>
                    </FormControl>
                </Grid> */}

                {/* <Grid item xs={12} md={4} 
                    sx={{ 
                        paddingLeft: { xs: '0rem', md: '2rem' },
                        paddingTop: { xs: '0.75rem', md: '0rem' },
                        paddingBottom: { xs: '0.75rem', md: '0rem' }
                    }}>
                    <FormControl fullWidth>
                        <InputLabel>Format</InputLabel>
                        <Select
                            labelId="lblSearchFormat"
                            id="lstSearchFormat"
                            value={tmpSearchParams.format}
                            label="Format"
                            onChange={(e: SelectChangeEvent) => setTmpSearchParams({...tmpSearchParams, format: e.target.value})}
                            >
                                {formatOptions.map(function(item:any) {
                                    return (
                                        <MenuItem key={uuidv4()} value={item.key}>{item.value}</MenuItem>
                                    )
                                })}
                        </Select>
                    </FormControl>
                </Grid> */}

                <Grid item xs={12} md={8}>
                    <Button variant="contained" size="large" 
                        sx={{ 
                            marginTop: '0.5rem',
                            marginLeft: { xs: '0rem', md: '2rem'},
                            marginRight: '2rem', 
                            marginBottom: { xs: '0rem', md: '0.5rem'},
                            width: { xs: '100%', md: 'auto' }
                        }}
                        onClick={handleSearch} title='Search discogs'
                    >
                        Search Discogs
                    </Button>

                    <Button variant="outlined" size="large" 
                        sx={{  
                            marginTop: '0.5rem',
                            marginBottom: '0.5rem',
                            width: { xs: '100%', md: 'auto' }
                        }}
                        onClick={clearSearch} title='Clear search criteria'
                    >
                        Clear
                    </Button>
                </Grid>

            </Grid>

            { allData?.results?.length > 0 && 
                <Grid container className='page-header-row' mb={3}>
                    <Grid item xs={12}><h2>Search Results</h2></Grid>
                </Grid>
            }

            <Grid container>
                {allData?.results?.map(function(item:any) {
                    return (
                        <SearchResultsRow item={item} key={uuidv4()} />
                    )
                })}
            </Grid>

            {/* <Grid container>
                <Grid item xs={12}>
                    
                <TabContext value={selectedTab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={(e: React.SyntheticEvent, newValue: string) => setSelectedTab(newValue)} 
                            aria-label="Search Results">
                            <Tab label="All" value="1" />
                            <Tab label="Masters" value="2" />
                            <Tab label="Releases" value="3" />
                            <Tab label="Artists" value="4" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <Grid container>
                            {allData?.map(function(item:any) {
                                return (
                                    <SearchResultsRow item={item} key={uuidv4()} />
                                )
                            })}
                        </Grid>
                    </TabPanel>
                    <TabPanel value="2">
                        <Grid container>
                            {masters?.map(function(item:any) {
                                return (
                                    <SearchResultsRow item={item} key={uuidv4()} />
                                )
                            })}
                        </Grid>
                    </TabPanel>
                    <TabPanel value="3">
                        <Grid container>
                            {releases?.map(function(item:any) {
                                return (
                                    <SearchResultsRow item={item} key={uuidv4()} />
                                )
                            })}
                        </Grid>
                    </TabPanel>
                    <TabPanel value="4">
                        <Grid container>
                            {artists?.map(function(item:any) {
                                return (
                                    <SearchResultsRow item={item} key={uuidv4()} />
                                )
                            })}
                        </Grid>
                    </TabPanel>
                </TabContext>

                </Grid>
            </Grid> */}

            {/* <CollectionPagination page={page} setPage={setPage} pagination={allData?.pagination} /> */}

        </div>
    )
}

export default SearchDiscogs;