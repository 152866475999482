import { Grid, Link, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { gql, useQuery } from '@apollo/client';
import { gqlGetNotifications } from '../../gql';

import { NotificationType } from '../../enums/NotificationType';

import { v4 as uuidv4 } from 'uuid';
import { fixDate } from '../../utils/dates';
import { useNavigate } from 'react-router-dom';

import MarkunreadIcon from '@mui/icons-material/Markunread';
import { NotificationViewType } from '../../enums/NotificationViewType';

import HeadphonesIcon from '@mui/icons-material/Headphones';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import NotesIcon from '@mui/icons-material/Notes';

import './Notifications.scss';
import { getForceUpdate } from '../../redux/sliceNotifications';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setForceUpdate } from '../../redux/sliceNotifications';
import CustomSnackBar, { ICustomSnackState } from '../custom-snack-bar/CustomSnackBar';
import DeleteNotificationButton from './DeleteNotificationButton';
import MarkNotificationReadButton from './MarkNotificationReadButton';
import AddNotesDialogButton from './AddNotesDialogButton';
import { setLoading } from '../../redux/sliceLoading';

type NotificationsProps = {
    viewType: NotificationViewType,
    getUnread: boolean | null
}

const Notifications:React.FunctionComponent<NotificationsProps> = ({ viewType, getUnread }) => {
    const nav = useNavigate();
    const [notificationData, setNotificationData] = useState<any[]>([]);

    // default query to get data
    // popover gets only unread messages
    // fullscreen gets all messages
    // this is based on the getUnread flag; if true, get unread - if null get all
    const queryMessages = gqlGetNotifications(viewType === NotificationViewType.POPOVER ? false : null);
    const { error, data, loading, refetch:refetchNotifications } = useQuery(gql`${queryMessages.query}`, { 
        variables: queryMessages.variables,
        pollInterval: viewType === NotificationViewType.POPOVER ? parseInt(process.env.REACT_APP_NOTIFICATION_POLLING_FREQUENCY!) : 0,
        fetchPolicy: 'no-cache'
    });
    
    // use redux toolkit dispatch
    const dispatch = useAppDispatch();

    // if the forceupdate state value is set to true, then the query will refetch
    const forceUpdate = useAppSelector((state) => getForceUpdate(state));
    useEffect(() => {
        if (forceUpdate === true) {
            refetchNotifications();
            dispatch(setForceUpdate(false));
        }
    }, [forceUpdate, dispatch, refetchNotifications]);

    // happens when notfication data is returned
    useEffect(() => {

        if (data) {
            setNotificationData(data?.getNotifications);          
        }
        
        if (error)
            console.error(error);

    }, [data, error, dispatch, viewType])

    const gotoLink = (link:string) => {
        nav(link);
    }

    // snack bar
    const [snackState, setSnackState] = useState<ICustomSnackState>({ open: false, 
        severity: "success", message: "", hideDuration: 5000 });
    
    // use redux toolkit dispatch
    useEffect(() => {
        dispatch(setLoading(loading));
    }, [loading, dispatch])

    return (
        <>
            
            <CustomSnackBar setCustomSnackState={setSnackState} state={snackState} />

            { viewType === NotificationViewType.FULLSCREEN && 
                <>

                    <Grid container className='page-header-row' mb={3}>
                        <Grid item xs={12}><h1>Notifications <MarkunreadIcon fontSize="large" /></h1></Grid>
                    </Grid>

                    {notificationData?.map(function(item:any) {
                        return (
                            <Grid container key={uuidv4()} sx={{ pt: 1, pl: 2, pr: 2, pb: 2}}>
                                <Grid item xs={1} sm={1} md={1}>
                                    { item?.fromUserAvatar && 
                                        <img className='avatar-image' alt={`User avatar for ${item?.fromUsername}`} 
                                            style={{ marginRight: '1rem', marginTop: '0.5rem' }}
                                            src={item?.fromUserAvatar} height={30} width={30} />
                                    }
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} className='notification-icon'>
                                    { item?.notificationType === NotificationType.LISTENED_WITH &&
                                        <HeadphonesIcon />
                                    }

                                    { item?.notificationType === NotificationType.LIKED &&
                                        <ThumbUpIcon />
                                    }

                                    { item?.notificationType === NotificationType.FOLLOWED &&
                                        <PersonAddIcon />
                                    }

                                    { item?.notificationType === NotificationType.NOTES_ADDED &&
                                        <NotesIcon />
                                    }
                                </Grid>
                                <Grid item xs={8} sm={8} md={8}>
                                    <Link onClick={() => gotoLink(`../timeline/user/${item?.fromUsername}`)}
                                        underline='hover'
                                        sx={{ cursor: 'pointer'}}
                                    >
                                        {item.fromUsername}
                                    </Link>

                                    { item?.notificationType === NotificationType.LISTENED_WITH && 
                                        ` tagged you in a listening session for ${item?.releaseName}`}
                                    { item?.notificationType === NotificationType.LIKED && 
                                        ` liked that you listened to ${item?.releaseName}`}
                                    { item?.notificationType === NotificationType.FOLLOWED && 
                                        ` started following you`}
                                    { item?.notificationType === NotificationType.NOTES_ADDED && 
                                        ` added notes to your listening session for ${item?.releaseName}`}
                                    <br />
                                    <Typography variant='caption'>{fixDate(item?.timestamp)}</Typography>

                                    { item?.notificationType === NotificationType.LISTENED_WITH && 
                                        <span>
                                            <AddNotesDialogButton 
                                                id={item?.listenId} 
                                                snackState={snackState}
                                                setCustomSnackState={setSnackState}
                                            />
                                        </span>
                                    }
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}>
                                    { item?.read === false &&
                                        <MarkNotificationReadButton
                                            id={item?._id}
                                            setNotificationData={setNotificationData}
                                        />
                                    }
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}>
                                    <DeleteNotificationButton 
                                        deleteId={item?._id}
                                        viewType={viewType}
                                        snackState={snackState}
                                        setCustomSnackState={setSnackState}
                                     />
                                </Grid>
                            </Grid>
                        )
                    })}

                </>
            }
            
        </>
    )
}

export default Notifications;