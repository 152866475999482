import { gql, useLazyQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { gqlSetIdentity } from '../../gql';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { setCurrentUser } from '../../redux/sliceUser';

const DiscogsIdentity:React.FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate();

    const token = useAppSelector((state) => state?.discogs?.oauth_token);
    const secret = useAppSelector((state) => state?.discogs?.oauth_secret);
    const verifier = useAppSelector((state) => state?.discogs?.oauth_verifier);

    const identityQuery = gqlSetIdentity(token, secret, verifier);
    const [setUserData, {error, data}] = useLazyQuery(gql`${identityQuery.query}`, {variables:identityQuery.variables});

    useEffect(() => {
        if (token !== undefined && token !== '' && 
            secret !== undefined && secret !== '' && 
            verifier !== undefined && verifier !== '') {
            setUserData();
        }
    }, [token, secret, verifier, setUserData]);

    useEffect(() => {
        if (data?.setIdentity?.success) {
            dispatch(setCurrentUser(data?.setIdentity?.user));
            navigate("../discogs/profile");
        }

        if (error)
            console.error(error);

    }, [data, error, dispatch, navigate])

    return (
        <>
        </>
    )
}

export default DiscogsIdentity;