import * as gqlbuilder from 'gql-query-builder';

export const gqlContactMessagesGetAll = () => gqlbuilder.query({
    operation: 'getContactMessages',
    fields: ['_id', 'subject', 'body', 'from', 'fromEmail', 'timestamp', 
                { fromUser: ['email','discogsUsername', 'userId'] } 
            ],
    variables: {
        
    }
});