import React, { useEffect, useRef, useState } from 'react';

import './Friends.scss';
import { Alert, AlertColor, Box, Card, CardContent, FormControl, Grid, IconButton, Snackbar, TextField, Typography } from '@mui/material';

import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import RemoveIcon from '@mui/icons-material/Remove';
import TimelineIcon from '@mui/icons-material/Timeline';
import PeopleIcon from '@mui/icons-material/People';

import { gqlAddFriend, gqlRemoveFriend, gqlSearchUsers } from '../../gql';
import { useLazyQuery, gql, useMutation } from "@apollo/client";
import { LoadingButton } from '@mui/lab';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getUser, setUserFriends } from '../../redux/sliceUser';

interface SnackProps {
    open: boolean;
    message: string;
    severity: AlertColor;
}

const Friends:React.FunctionComponent = () => {
    const user = useAppSelector((state) => getUser(state));
    const topRef = useRef<null | HTMLDivElement>(null);
    const dispatch = useAppDispatch();

    const [search, setSearch] = useState('');
    const [usernameSearch, setUsernameSearch] = useState('');
    const [userData, setUserData] = useState<any>([]);
    const [userId, setUserId] = useState('');
    const [removeUserId, setRemoveUserId] = useState('');
    const [snack, setSnack] = useState<SnackProps>({
        open: false, severity: 'success', message: ''
    })

    const handleSearchUsers = () => {
        setSearch(usernameSearch);
    }

    const gqlSearch = gqlSearchUsers(search);
    const [searchUsers, {loading, error, data}] = useLazyQuery(gql`${gqlSearch.query}`, 
        { variables: gqlSearch.variables, errorPolicy: 'all' }
    );

    const gqlAdd = gqlAddFriend(userId);
    const [addFriend, { error:addError, data:addData}] = useMutation(gql`${gqlAdd.query}`, 
        { variables: gqlAdd.variables, errorPolicy: 'all' }
    );

    const gqlRemove = gqlRemoveFriend(removeUserId);
    const [removeFriend, { error:removeError, data:removeData}] = useMutation(gql`${gqlRemove.query}`, 
        { variables: gqlRemove.variables, errorPolicy: 'all' }
    );

    useEffect(() => {
        if (data)
            setUserData(data?.searchUsers);

        if (error)
            console.error(error);
    }, [data, error])

    useEffect(() => {
        
        if (search !== '') {
            searchUsers();
        }
            
    }, [search, searchUsers])

    const handleAdd = (id:string) => {
        setUserId(id);
    }

    useEffect(() => {

        if (userId !== '')
            addFriend();

    }, [userId, addFriend]);

    useEffect(() => {
        if (addData?.addFriend && !addError) {
            setUserId('')
            setUserData([]);
            setUsernameSearch('');
            dispatch(setUserFriends(addData?.addFriend));
            setSnack({ open: true, message: "Friend added!", severity: 'success' });
        }

        if (addError) {
            console.log("addError", addError);
            setSnack({ open: true, message: addError?.message, severity: 'error' })
        }

    }, [addData, addError, dispatch])

    const handleClearSearch = () => {
        setUsernameSearch('');
        setUserData([]);
    }

    const handleRemoveFriend = (id:string) => {
        setRemoveUserId(id);
    }

    useEffect(() => {
        if (removeUserId !== '')
            removeFriend();
    }, [removeUserId, removeFriend])

    useEffect(() => {
        if (removeData?.removeFriend && !removeError) {
            setRemoveUserId('');
            dispatch(setUserFriends(removeData?.removeFriend));
            setSnack({ open: true, message: "Friend removed!", severity: 'success' });
        }

        if (removeError) {
            console.log("removeError", removeError);
            setSnack({ open: true, message: removeError?.message, severity: 'error' })
        }
    }, [removeData, removeError, dispatch]);

    const handleCloseSnack = () => {
        setSnack({...snack, open: false});
    }
    
    return (
        <div>
            <div ref={topRef}></div>
            <Grid container className='page-header-row' mb={3}>
                <Grid item xs={12}><h1>My Friends <PeopleIcon fontSize='large' /></h1></Grid>
            </Grid>

            <Snackbar open={snack?.open} autoHideDuration={5000} onClose={handleCloseSnack}
                anchorOrigin={{ vertical: 'top', horizontal: 'left'}}>
                <Alert onClose={handleCloseSnack} severity={snack?.severity} sx={{ width: '100%' }}>
                    {snack?.message}
                </Alert>
            </Snackbar>

            <Grid container mb={3} spacing={3}>
                <Grid item xs={12} md={4}>

                    <Card>
                        <CardContent>
                            <Typography mb={3}
                                sx={{ fontSize: 14, fontWeight: 'bold' }}
                                gutterBottom
                            >
                                Search for friends
                            </Typography>

                            <Box mb={3}>
                                <TextField id="outlined-basic" label="Username search" variant="outlined"
                                    type=''
                                    value={usernameSearch} fullWidth
                                    onChange={(e:React.ChangeEvent<HTMLInputElement>) => setUsernameSearch(e.currentTarget.value)}
                                    InputProps={{
                                        endAdornment: usernameSearch && (
                                            <IconButton onClick={handleClearSearch}>
                                                <ClearIcon />
                                            </IconButton>
                                        )
                                    }}  
                                />
                            </Box>

                            <Box mb={3}>
                                <FormControl fullWidth>
                                    <LoadingButton loading={loading} loadingPosition='start'
                                        variant="contained" size="large" startIcon={<PersonSearchIcon />}
                                        sx={{
                                            width: { xs: '100%', md: 'auto' }
                                        }}
                                        onClick={handleSearchUsers} title='Search Users'
                                    >
                                        Search Users
                                    </LoadingButton>
                                </FormControl>
                            </Box>

                            <Grid container>
                                {userData?.map(function(item:any) {
                                    return (
                                        <Grid item xs={12} key={item?._id}>

                                            <Grid container>
                                                <Grid item xs={2} mt={1}>
                                                    { item?.discogsUserAvatar && 
                                                        <img className='avatar-image' alt={`User avatar for ${item?.discogsUsername}`} style={{ marginRight: '1rem'}}
                                                            src={item?.discogsUserAvatar} height={30} width={30} />
                                                    }
                                                </Grid>

                                                <Grid item xs={8} mt={1}>{item.discogsUsername}</Grid>

                                                <Grid item xs={2}>
                                                    <IconButton color="primary" aria-label="add friend"
                                                        onClick={() => handleAdd(item._id)}
                                                        title={`Add ${item?.discogsUsername} to your friends`} >
                                                        <AddIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                            
                                        </Grid>
                                        )
                                    })}
                            </Grid>

                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={8}>

                    <Card>
                        <CardContent>

                            <Typography mb={3}
                                sx={{ fontSize: 14, fontWeight: 'bold' }}
                                gutterBottom
                            >
                                Friends List
                            </Typography>

                                { (!user?.friends || user?.friends?.length === 0) && 
                                    <Typography>You currently have no friends ಥ_ಥ </Typography>
                                }

                                {user?.friends?.map(function(item:any) {
                                    return (
                                        <Grid container key={item?._id}>
                                            <Grid item xs={2} mt={1} mb={3}>
                                                { item?.discogsUserAvatar && 
                                                    <img className='avatar-image' alt={`User avatar for ${item?.discogsUsername}`} style={{ marginRight: '1rem'}}
                                                        src={item?.discogsUserAvatar} height={30} width={30} />
                                                }
                                            </Grid>
                                            <Grid item xs={6} mt={1} mb={3}>
                                                {item.discogsUsername}
                                            </Grid>
                                            <Grid item xs={2} mb={3} sx={{ textAlign: 'center'}}>
                                                <IconButton href={`../timeline/user/${item?.discogsUsername}`}
                                                    title={`Timeline for ${item?.discogsUsername}`}
                                                    aria-label={`Timeline for ${item?.discogsUsername}`}
                                                    color='secondary'
                                                >
                                                    <TimelineIcon />
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs={2} mb={3} sx={{ textAlign: 'center'}}>
                                                <IconButton color="error" aria-label="remove friend"
                                                    title={`Remove ${item?.discogsUsername} from your friends`}
                                                    onClick={() => handleRemoveFriend(item._id)}>
                                                    <RemoveIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    )
                                })}

                                {/* <Grid item xs={2} mt={1}>
                                    { item?.discogsUserAvatar && 
                                        <img className='avatar-image' alt="User Avatar" style={{ marginRight: '1rem'}}
                                            src={item?.discogsUserAvatar} height={30} width={30} />
                                    }
                                </Grid>

                                <Grid item xs={8} mt={1}>{item.discogsUsername}</Grid>

                                <Grid item xs={2}>
                                    <IconButton color="primary" aria-label="add friend"
                                        onClick={() => handleAdd(item._id)}>
                                        <AddIcon />
                                    </IconButton>
                                </Grid> */}
                            

                        </CardContent>
                    </Card>
                </Grid>

            </Grid>

        </div>
    )
}

export default Friends;