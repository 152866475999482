import React, { useEffect } from 'react';
import { Checkbox, FormControl, FormControlLabel, Grid, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, Switch, TextField } from '@mui/material';

import './TimelineEntryLayout.scss';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';

import CustomRating from '../custom-rating/CustomRating';
 
export type TimelineEntryLayoutProps = {
    user: any;
    title: string;
    thumb: string;
    artists: string;
    notes: string;
    setNotes?: React.Dispatch<React.SetStateAction<string>> | null;
    datetime: dayjs.Dayjs | null;
    setDatetime?: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>> | null;
    readonly?: boolean;
    listenedWith: any[];
    setListenedWith: React.Dispatch<React.SetStateAction<any[]>> | null;
    rating: number;
    setRating: React.Dispatch<React.SetStateAction<number>> | null;
    fromQueue?: boolean | null;
    removeFromQueue?: boolean | null;
    setRemoveFromQueue?: React.Dispatch<React.SetStateAction<boolean>> | null;
}

const TimelineEntryLayout:React.FunctionComponent<TimelineEntryLayoutProps> = ({ user, title, thumb, artists, 
    notes, setNotes, datetime, setDatetime, readonly = false, listenedWith, setListenedWith,
    rating, setRating, fromQueue, removeFromQueue, setRemoveFromQueue }) => {

    let people:string[] = [];
    if (listenedWith) {
        for (let i = 0; i < listenedWith?.length; i++) {
            people.push(listenedWith[i]?.username);
        }
    }
    const [personName, setPersonName] = React.useState<string[]>(people);

    const handleChange = (event: SelectChangeEvent<typeof personName>) => {
        const {
          target: { value },
        } = event;
        setPersonName(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );

        let tmpWith = [];
        for (let i = 0; i < event?.target?.value?.length; i++) {
            const item = event?.target?.value[i];
            
            const aFriend = user?.friends?.filter((friend:any) => {
                return friend?.discogsUsername === item;
            });
            if (aFriend.length > 0) {
                const f = { userId: aFriend[0]?._id, username: aFriend[0]?.discogsUsername };
                tmpWith.push(f);
            }
        }

        if (setListenedWith)
            setListenedWith(tmpWith);
    };

    useEffect(() => {
        //console.log("personName", personName);
    }, [personName])

    const handleRemoveFromQueue = (e:React.ChangeEvent<HTMLInputElement>) => {
        if (setRemoveFromQueue)
            setRemoveFromQueue(e.target.checked);
    }

    return (
        <div>

            <Grid container mb={3}>
                
                <Grid item xs={12} sm={12} md={12} lg={12} mb={2}>
                    <h2>{title}</h2>
                </Grid>
                <Grid item xs={6} sm={6} md={2} lg={2} mb={2}>
                    <img src={thumb} alt={`Cover for ${title}`} />
                </Grid>
                <Grid item xs={6} sm={6} md={2} lg={2}>
                    <strong>Artist(s):</strong> {artists}
                </Grid>
                
            </Grid>

            { setNotes && 
                <Grid container mb={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} mb={2}>
                        <TextField id="outlined-basic" label="Listening Notes" variant="outlined"
                            value={notes} fullWidth
                            disabled={readonly}
                            onChange={(e:React.ChangeEvent<HTMLInputElement>) => setNotes(e.currentTarget.value)}
                        />
                    </Grid>
                </Grid>
            }

            { !setNotes && 
                <Grid container mb={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} mb={2}>
                        <TextField id="outlined-basic" label="Listening Notes" variant="outlined"
                            defaultValue={notes}
                            fullWidth
                            disabled={readonly}
                        />
                    </Grid>
                </Grid>
            }
            
            { setDatetime && 
                <Grid container mb={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} mb={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                value={datetime}
                                disabled={readonly}
                                maxDate={dayjs(new Date())}
                                onChange={(newValue) => setDatetime(newValue)}
                                sx={{ width: { xs: '100%', md: 'auto' } }}
                                label="Listened At" />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            }

            { !setDatetime && 
                <Grid container mb={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} mb={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                defaultValue={datetime}
                                disabled={readonly}
                                maxDate={dayjs(new Date())}
                                sx={{ width: { xs: '100%', md: 'auto' } }}
                                label="Listened At" />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            }

            <Grid container mb={3}>
                <Grid item xs={12} sm={12} md={6} lg={6} mb={2}>

                    <FormControl fullWidth>
                        <InputLabel id='lblListenedWith'>Listened With</InputLabel>
                        <Select
                            labelId="lblListenedWith"
                            id="lstListenedWith"
                            value={personName}
                            label="Listened With"
                            onChange={handleChange}
                            multiple
                            renderValue={(selected) => selected.join(', ')}
                            disabled={readonly}
                            >
                                {user?.friends?.map((friend: { _id: string; discogsUsername: string; discogsUserAvatar: string; }) => (
                                    <MenuItem key={friend?._id} value={friend?.discogsUsername}>
                                        <Checkbox checked={personName.indexOf(friend?.discogsUsername) > -1} />
                                        <img className='avatar-image avatar-image-margin' 
                                            alt={`Avatar for ${friend?.discogsUsername}`} 
                                            src={friend?.discogsUserAvatar} height={25} width={25} />
                                        <ListItemText primary={friend?.discogsUsername} />
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>              

                </Grid>
            </Grid>

            <Grid container mb={3}>
                <Grid item xs={12} sm={12} md={6} lg={6} mb={2}>
                    <InputLabel id='lblRating'>Rating</InputLabel>

                    <CustomRating size="large" 
                        rating={rating} 
                        setRating={setRating} 
                        readonly={false} 
                    />
                </Grid>
            </Grid>

            { fromQueue === true && 
                <Grid container mb={3}>
                    <Grid item xs={12} mb={2}>
                        <InputLabel>Remove from queue?</InputLabel>
                        <span className='switch-control-off'>No</span>
                        <FormControlLabel control={<Switch 
                            checked={removeFromQueue!} 
                            onChange={handleRemoveFromQueue}
                            inputProps={{ 'aria-label': 'Remove from queue'}} />} 
                        label="Yes" />
                    </Grid>
                </Grid>
            }
            
        </div>
    )
}

export default TimelineEntryLayout;