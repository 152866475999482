import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, LinearProgress, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { gqlAddListeningNotes, gqlGetListeningNote } from '../../gql';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { ICustomSnackState } from '../custom-snack-bar/CustomSnackBar';

import EditIcon from '@mui/icons-material/Edit';

interface IAddNotesDialogButton {
    id: string;
    snackState: ICustomSnackState;
    setCustomSnackState: React.Dispatch<React.SetStateAction<ICustomSnackState>>;
    buttonType?: 'button' | 'icon';
    setRefetchData?: React.Dispatch<React.SetStateAction<boolean>> | null;
}

const AddNotesDialogButton:React.FunctionComponent<IAddNotesDialogButton> = ({
    id, snackState, setCustomSnackState, buttonType = 'button', setRefetchData
}) => {

    // notes dialog
    const [notesDialogOpen, setNotesDialogOpen] = useState(false);
    const [notesListenInfo, setNotesListenInfo] = useState({ id: id, notes: "" });
    const addNotesQuery = gqlAddListeningNotes(notesListenInfo?.id, notesListenInfo.notes);
    const [addNotesMutation, { loading, error, data}] = useMutation(gql`${addNotesQuery.query}`, {
        variables: addNotesQuery.variables,
    });
    const [wording, setWording] = useState({ 
        title: "Add", 
        successAction: "added", 
        caption: "To add your notes to this"
    });

    // get notes if they already exists
    const getNoteQuery = gqlGetListeningNote(notesListenInfo?.id);
    const [getNote, {loading:noteLoading, error:noteError, data:noteData}] = 
        useLazyQuery(gql`${getNoteQuery.query}`, {
            variables: getNoteQuery.variables,
            fetchPolicy: 'network-only'
        })

    const handleNotesDialogClickOpen = (id:string) => {
        getNote();
        setNotesDialogOpen(true);
    };

    const handleNotesDialogClose = () => {
        setNotesDialogOpen(false);
    };

    const handleListenNotesUpdate = (e:React.ChangeEvent<HTMLInputElement>) => {
        setNotesListenInfo({...notesListenInfo, notes: e.currentTarget.value});
    }

    const handleNotesDialogAdd = () => {

        if (notesListenInfo.id !== "" && notesListenInfo.notes !== "")
            addNotesMutation();
        
    }

    useEffect(() => {

        if (data?.addListeningNotes && notesListenInfo.id !== "") {
            if (data?.addListeningNotes?.success === true) {
                setNotesDialogOpen(false);
                setCustomSnackState({ ...snackState, open: true, severity: "success", message: `Listening notes ${wording.successAction}!`});
                setNotesListenInfo({ id: "", notes: ""});

                if (setRefetchData)
                    setRefetchData(true);
            }
        }

        if (error)
            console.error(error);

    }, [data, error, snackState, notesListenInfo, setCustomSnackState, wording, setRefetchData]);

    useEffect(() => {

        if (noteData?.getListeningNote?.notes) {
            console.log("noteData", noteData);
            setNotesListenInfo({...notesListenInfo, notes: noteData?.getListeningNote?.notes});
            setWording({...wording, 
                title: "Edit", 
                successAction: "updated",
                caption: "To update your notes from this"
            });
        }

        if (noteError)
            console.error(noteError);

    // eslint-disable-next-line
    }, [noteData, noteError]);

    useEffect(() => {

        //if (notesDialogOpen === true)
            //refecthNote();

    }, [notesDialogOpen])

    return (
        <>

            <Dialog open={notesDialogOpen} onClose={handleNotesDialogClose}>
                <DialogTitle>{wording.title} Listening Notes</DialogTitle>
                <DialogContent>
                    { (loading || noteLoading) && <LinearProgress color="secondary" />}
                    <DialogContentText>
                        {wording.caption} session, enter them here. They will then show up on the user's timeline.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="notes"
                        label="Listening notes"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={notesListenInfo?.notes}
                        onChange={handleListenNotesUpdate}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleNotesDialogClose}>Cancel</Button>
                    <Button onClick={handleNotesDialogAdd}>{wording.title} my notes</Button>
                </DialogActions>
            </Dialog>

            { buttonType === 'button' &&
                <Button variant="text" 
                    onClick={() => handleNotesDialogClickOpen(id)}>Add your notes
                </Button>
            }

            { buttonType === 'icon' && 
                <IconButton color="primary" size='small' aria-label="edit notes" title='Edit notes'
                    onClick={() => handleNotesDialogClickOpen(id)}>
                    <EditIcon fontSize='small' />
                </IconButton>
            }

        </>
    )
}

export default AddNotesDialogButton;