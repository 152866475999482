import React from 'react';
import { Alert, AlertColor, Snackbar } from '@mui/material';

export interface ICustomSnackProps {
    state: ICustomSnackState;
    setCustomSnackState: React.Dispatch<React.SetStateAction<ICustomSnackState>> | null;
}

export interface ICustomSnackState {
    open: boolean;
    severity: AlertColor;
    message: string;
    hideDuration: number;
}

const CustomSnackBar:React.FunctionComponent<ICustomSnackProps> = ({ state, setCustomSnackState }) => {

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }

        if (setCustomSnackState)
            setCustomSnackState({...state, open: false });
    };

    return (
        <>
            <Snackbar open={state?.open} autoHideDuration={state?.hideDuration} 
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={state?.severity} sx={{ width: '100%' }}>
                    {state?.message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default CustomSnackBar;