import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const fixDate = (date:number) => {
    const d = new Date(0);
    const newDate = new Date(d.setUTCSeconds(date));
    return `${newDate.toLocaleDateString()} ${newDate.toLocaleTimeString()}`;
};

export function secondsToHms(d:number) {

    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor(d % 3600 / 60);
    const s = Math.floor(d % 3600 % 60);

    const hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
    const mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
    const sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";

    if (!hDisplay && !mDisplay && !sDisplay)
        return 'Unable to calculate total listening time';

    return hDisplay + mDisplay + sDisplay; 
};

export function determineListeningTime(data:any[]) {
    const total:number = data.reduce(function(sum:number, item:any) {
        //const timeString = item?.listenedTo?.tracklist;
        //console.log(timeString);
        
        // get the total time from the duration strings
        const totalTime = item?.listenedTo?.tracklist?.reduce(function(sum:number, track:any) {
            // get the number of seconds for all tracks based on duration string
            const seconds:number = track?.duration?.split(':').reduce((acc:number,time:number) => (60 * acc) + +time);
            
            //console.log("tracklist seconds", seconds);

            // return the sum of tracks on this album
            return sum + (seconds*1);
        }, 0);
        //console.log("totalAlbumTime", totalTime);

        // return the sum of all tracks on all albums
        return (sum*1) + (totalTime*1);
    }, 0);

    return total;
};

export function getCurrentTimestamp() {
    return dayjs.utc(dayjs(new Date())).utc().unix();
}