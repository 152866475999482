import * as gqlbuilder from 'gql-query-builder';
import ISearchDiscogs from './interfaces/ISearchDiscogs';

export const gqlUserListens = (dateRange:any, username?:string, releaseId?:number) => gqlbuilder.query({
    operation: 'getListens',
    fields: ['_id', 'releaseId', 'timestamp', 'notes', 'listenCount', 'userId', 'rating', 
        { likes: ['timestamp','userId','username'] },
        { listenedWith: ['userId','username','notes']},
        {
        listenedTo: [
            'master_id', 'master_url','artists_sort','resource_url','uri','thumb','cover_image','title',
                { images: ['height','width','resource_url','type','uri','uri150'] },
                { tracklist: ['title','duration'] }
        ],
    }],
    variables: {
        start: { type: "Int!", value: dateRange?.start },
        end: { type: "Int!", value: dateRange?.end },
        username: { type: "String", value: username },
        releaseId: { type: "Int", value: releaseId }
    }
});

export const gqlRequestDiscogsToken = () => gqlbuilder.query({
    operation: 'requestToken',
    fields: ['oauth_token','oauth_token_secret']
});

export const gqlRequestDiscogsAccess = (token:string, secret:string, verifier:string) => gqlbuilder.query({
    operation: 'accessToken',
    fields: ['oauth_token','oauth_token_secret'],
    variables: {
        accessTokenRequest: {
            type: "AccessTokenRequest!",
            value: {
                oauth_token: token,
                oauth_token_secret: secret,
                oauth_verifier: verifier
            }
        }
    }
});

export const gqlGetUserCollection = (username:string, page: number, sort:string, order:string, 
        searchTerm:string, folder:string) => gqlbuilder.query({
    operation: 'getCollection',
    fields: [ 'searchResultCount', 
        { pagination: ['page','pages','per_page','items', { urls: ['first','last','prev','next']} ] },
        { releases: ['id', 'folder_id', 'rating', 'instance_id', 
                { basic_information: ['id','master_id', 'master_url',
                    'artists_sort','title','thumb','cover_image', 'year', 'genres', 'uri',
                    { artists: ['name','id','resource_url']},
                    { formats: ['name'] }
                ]
                }
          ] 
        }
    ],
    variables: {
        page: { type: "Float!", value: page},
        username: { type: "String!", value: username},
        sort: { type: "String!", value: sort},
        order: { type: "String!", value: order},
        searchTerm: { type: "String!", value: searchTerm},
        folder: { type: "Float!", value: parseInt(folder)}
    }
});

export const gqlSetIdentity = (token:string, secret:string, verifier:string) => gqlbuilder.query({
    operation: 'setIdentity',
    fields: ['success', { user: ['_id','userId','username','email','roles','discogsUserId',
                                'discogsUsername','discogsUserUrl','discogsUserAvatar', 
                            {friends: ['_id','discogsUsername','discogsUserAvatar','createdAt']}]
                          }
    ],
    variables: {
        accessTokenRequest: {
            type: "AccessTokenRequest!",
            value: {
                oauth_token: token,
                oauth_token_secret: secret,
                oauth_verifier: verifier
            }
        }
    }
});

export const gqlGetIdentity = (token:string, secret:string, verifier:string) => gqlbuilder.query({
    operation: 'getIdentity',
    fields: ['consumer_name','id','resource_url','username'],
    variables: {
        accessTokenRequest: {
            type: "AccessTokenRequest!",
            value: {
                oauth_token: token,
                oauth_token_secret: secret,
                oauth_verifier: verifier
            }
        }
    }
});

export const gqlAddListen = (releaseId:number, notes:string, timestamp:number, 
    rating:number, listenedWith:any, removeFromQueue?:string) => gqlbuilder.mutation({
    operation: 'addListen',
    fields: ['id','success'],
    variables: {
        id: { type: "Int!", value: releaseId },
        notes: { type: "String!", value: notes },
        timestamp: { type: "Int!", value: timestamp },
        rating: { type: "Int!", value: rating }, 
        listenedWith: { type: "[ListenWithInput!]", value: listenedWith},
        ...((removeFromQueue !== undefined && removeFromQueue !== null) && { removeFromQueueId: { type: "String", value: removeFromQueue } })
    }
});

export const gqlEditListen = (id:string, notes:string, timestamp:number, rating:number, listenedWith:any) => gqlbuilder.mutation({
    operation: 'editListen',
    fields: ['id','success'],
    variables: {
        id: { type: "String!", value: id },
        notes: { type: "String!", value: notes },
        timestamp: { type: "Int!", value: timestamp},
        rating: { type: "Int!", value: rating },
        listenedWith: { type: "[ListenWithInput!]", value: listenedWith}
    }
});

export const gqlDeleteListen = (id:string) => gqlbuilder.mutation({
    operation: 'deleteListen',
    fields: ['id','success'],
    variables: {
        id: { type: "String!", value: id }
    }
});

export const gqlGetFolders = (username: string) => gqlbuilder.query({
    operation: 'getFolders',
    fields: ['id','count','name'],
    variables: {
        username: {
            type: "String!", value: username
        }
    }
});

export const gqlSearchUsers = (username: string) => gqlbuilder.query({
    operation: 'searchUsers',
    fields: ['_id','discogsUsername','discogsUserAvatar'],
    variables: {
        username: {
            type: "String!", value: username
        }
    }
});

export const gqlAddFriend = (id:string) => gqlbuilder.mutation({
    operation: 'addFriend',
    fields: ['_id','discogsUsername','discogsUserAvatar','createdAt'],
    variables: {
        id: { type: "String!", value: id }
    }
});

export const gqlRemoveFriend = (id:string) => gqlbuilder.mutation({
    operation: 'removeFriend',
    fields: ['_id','discogsUsername','discogsUserAvatar','createdAt'],
    variables: {
        id: { type: "String!", value: id }
    }
});

export const gqlLikeListen = (id:string) => gqlbuilder.mutation({
    operation: 'likeListen',
    fields: ['_id', { likes: ['timestamp','userId','username'] }],
    variables: {
        id: { type: "String!", value: id }
    }
});

export const gqlUnlikeListen = (id:string) => gqlbuilder.mutation({
    operation: 'unlikeListen',
    fields: ['_id', { likes: ['timestamp','userId','username'] }],
    variables: {
        id: { type: "String!", value: id }
    }
});

export const gqlChangeRating = (folderId:number, releaseId:number, instanceId:number, rating:number) => gqlbuilder.mutation({
    operation: 'changeRating',
    fields: ['success','error'],
    variables: {
        folderId: { type: "Int!", value: folderId },
        releaseId: { type: "Int!", value: releaseId },
        instanceId: { type: "Int!", value: instanceId },
        rating: { type: "Int!", value: rating },
    }
});

export const gqlGetRelease = (releaseId:number) => gqlbuilder.query({
    operation: 'getRelease',
    fields: ['id','thumb','title','uri'],
    variables: {
        id: { type: "Int!", value: releaseId }
    }
});

export const gqlGetNotifications = (read?:boolean | null) => gqlbuilder.query({
    operation: 'getNotifications',
    fields: ['_id','fromUserId','fromUsername','fromUserAvatar','message','read','readAt',
        'timestamp','notificationType','releaseId','releaseName','listenId'
    ],
    variables: {
        //read: { type: "Boolean", value: read}
        ...((read !== undefined && read !== null) && { read: { type: "Boolean", value: read } })
    }
});

export const gqlMarkNotificationRead = (id:string, timestamp:number, read?:boolean | null) => gqlbuilder.mutation({
    operation: 'markNotificationRead',
    fields: ['_id','fromUserId','fromUsername','fromUserAvatar','message','read','readAt',
        'timestamp','notificationType','releaseId','releaseName'
    ],
    variables: {
        id: { type: "String!", value: id},
        timestamp: { type: "Int!", value: timestamp},
        ...((read !== undefined && read !== null) && { read: { type: "Boolean", value: read } })
    }
});

export const gqlAddListeningNotes = (id:string, notes: string) => gqlbuilder.mutation({
    operation: 'addListeningNotes',
    fields: ['success','error'],
    variables: {
        id: { type: "String!", value: id},
        notes: { type: "String!", value: notes}
    }
});

export const gqlDeleteNotification = (id:string, read?:boolean | null) => gqlbuilder.mutation({
    operation: 'deleteNotification',
    fields: ['success','error'],
    variables: {
        id: { type: "String!", value: id},
        ...((read !== undefined && read !== null) && { read: { type: "Boolean", value: read } })
    }
});

export const gqlGetListeningNote = (id:string) => gqlbuilder.query({
    operation: 'getListeningNote',
    fields: ['notes'],
    variables: {
        id: { type: "String!", value: id}
    }
});

export const gqlGetRandomUnplayed = (folder:string) => gqlbuilder.query({
    operation: 'getRandomUnplayed',
    fields: [{ releases: ['id', 'folder_id', 'rating', 'instance_id', 
                { basic_information: ['id','master_id', 'master_url',
                    'artists_sort','title','thumb','cover_image', { artists: ['name','id','resource_url']}]
                }
            ] 
    }
    ],
    variables: {
        folder: { type: "Float!", value: parseInt(folder)}
    }
});

export const gqlAddToQueue = (id:number) => gqlbuilder.mutation({
    operation: 'addToQueue',
    fields: ['success'],
    variables: {
        releaseId: { type: "Int!", value: id}
    }
});

export const gqlUserQueue = () => gqlbuilder.query({
    operation: 'getQueue',
    fields: ['_id', 'releaseId', 'timestamp', 'notes', 
        {
        basic_information: [
            'master_id', 'master_url','artists_sort','resource_url','uri','thumb','cover_image','title',
                { images: ['height','width','resource_url','type','uri','uri150'] },
                { tracklist: ['title','duration'] },
                { artists: ['name','id','resource_url'] }
        ],
    }],
    variables: { }
});

export const gqlDeleteFromQueue = (id:string) => gqlbuilder.mutation({
    operation: 'deleteFromQueue',
    fields: ['_id', 'releaseId', 'timestamp', 'notes', 
        {
        basic_information: [
            'master_id', 'master_url','artists_sort','resource_url','uri','thumb','cover_image','title',
                { images: ['height','width','resource_url','type','uri','uri150'] },
                { tracklist: ['title','duration'] },
                { artists: ['name','id','resource_url'] }
        ],
    }],
    variables: { 
        id: { type: "String!", value: id }
    }
});

export const gqlContactUs = (subject:string, body:string, recaptcha:string) => gqlbuilder.mutation({
    operation: 'contactEmail',
    fields: ['success','error'],
    variables: { 
        subject: { type: "String!", value: subject },
        body: { type: "String!", value: body },
        recaptcha: { type: "String!", value: recaptcha },
    }
});

export const gqlSearchDiscogs = (page: number, search:ISearchDiscogs) => gqlbuilder.query({
    operation: 'searchDiscogs',
    fields: [ 'searchResultCount', 
        { pagination: ['page','pages','per_page','items', { urls: ['first','last','prev','next']} ] },
        { results: ['id','master_id', 'master_url',
                'artists_sort','title','thumb','cover_image', 'year', 'genre', 'uri', 
                'catno', 'barcode', 'country', 'format', 'type',
                { artists: ['name','id','resource_url']},
                // { formats: ['name'] }
            ]
        }
    ],
    variables: {
        page: { type: "Float!", value: page},
        perPage: { type: "Float!", value: search?.perPage},
        sort: { type: "String!", value: search?.sort},
        order: { type: "String!", value: search?.order},
        searchTerm: { type: "String!", value: search?.searchTerm},
        searching: { type: "String!", value: search?.searching},
        format: { type: "String!", value: search?.format === "all" ? "" : search?.format}
    }
});

export const gqlDiscogsArtist = (id:number) => gqlbuilder.query({
    operation: 'getDiscogsArtist',
    fields: [
        { artist: ['id', 'name', 'namevariations','profile','urls', 
            { images: ['height','width','type','uri']} ]
        },
        // { releases: ['id','master_id', 'master_url',
        //     'artists_sort','title','thumb','cover_image', 'year', 'genre', 'uri', 
        //     'catno', 'barcode', 'country', 'format', 'type', 'label',
        //     { artists: ['name','id','resource_url']} ]
        // }
    ],
    variables: {
        id: { type: "Float!", value: id }
    }
});

export const gqlGetDiscogsRelease = (id:number) => gqlbuilder.query({
    operation: 'getDiscogsRelease',
    fields: [ 
        { artist: ['id', 'name', 'namevariations','profile','urls', 
            { images: ['height','width','type','uri']} ]
        },
        { release:[ 'id','master_id', 'master_url',
            'artists_sort','title','thumb','cover_image', 'year', 'genre', 'uri', 
            'catno', 'barcode', 'country', 'format',
            { artists: ['name','id','resource_url'] },
            { images: ['height','width','type','uri']}
        ] 
        }
    ],
    variables: {
        id: { type: "Float!", value: id}
    }
});

// export const gqlGetDiscogsMaster = (id:number, page:number, format:string) => gqlbuilder.query({
//     operation: 'getDiscogsMaster',
//     fields: [
//         { artist: ['id', 'name', 'namevariations','profile','urls', 
//             { images: ['height','width','type','uri']} ]
//         },
//         { releases: ['id','master_id', 'master_url',
//             'artists_sort','title','thumb','cover_image', 'year', 'genre', 'uri', 
//             'catno', 'barcode', 'country', 'format', 'type', 'label',
//             { artists: ['name','id','resource_url']} ]
//         },
//         { pagination: ['page','pages','per_page','items', { urls: ['first','last','prev','next']} ] },
//         { masterRelease: ['title', { images: ['height','width','resource_url','type','uri','uri150'] } ] }
//     ],
//     variables: {
//         id: { type: "Float!", value: id },
//         page: { type: "Float!", value: page },
//         format: { type: "String!", value: format }
//     }
// });

export const gqlGetDiscogsMaster = (id:number, page:number, format:string, label:string, 
    country:string, year:string) => gqlbuilder.query({
    operation: 'getDiscogsMaster',
    fields: [
        { artist: ['id', 'name', 'namevariations','profile','urls', 
            { images: ['height','width','type','uri']} ]
        },
        { versions: ['id','thumb','format','country','title','label','released',
            'major_formats','catno','resource_url'
        ]
        },
        { pagination: ['page','pages','per_page','items', { urls: ['first','last','prev','next']} ] },
        { masterRelease: ['title', { images: ['height','width','resource_url','type','uri','uri150'] } ] },
        { facets: ['title','id','allows_multiple_values', { values: ['title','value','count'] }]}
    ],
    variables: {
        id: { type: "Float!", value: id },
        page: { type: "Float!", value: page },
        format: { type: "String!", value: format },
        label: { type: "String!", value: label },
        country: { type: "String!", value: country },
        year: { type: "String!", value: year }
    }
});