import React from 'react';

import './Home.scss';
import { Grid } from '@mui/material';
//import secureLocalStorage from 'react-secure-storage';

const Home:React.FunctionComponent = () => {
    //secureLocalStorage.clear();

    return (
        <div>
            <Grid container className='page-header-row'>
                <Grid item xs={12}><h1>Welcome to {process.env.REACT_APP_SITE_NAME}!</h1></Grid>
                <Grid item xs={12}><h2>The digital log for your analog life</h2></Grid>
            </Grid>

            <Grid container className="spacer-row"></Grid>

            <Grid container>
                <Grid item xs={12}>
                    Discovering new music and revisiting your favorite albums is a journey worth remembering.
                    With {process.env.REACT_APP_SITE_NAME}, we've create a platform that allows you to easily keep track of the albums
                    you've listened to, share your musical adventures with friends, and explore the diverse world
                    of music like never before.
                </Grid>
            </Grid>

            <Grid container className="spacer-row"></Grid>

            <Grid container>
                <Grid item xs={12} mb={3}><h3>Features:</h3></Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} mb={2}>
                    <strong>1. Easy album tracking:</strong><br />
                    Easily log the albums you've listened to with just a few clicks. Our user-friendly interface
                    makes it simple to add add details like the album name, artist, and your personal Listening
                    notes.
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} mb={2}>
                    <strong>2. Your discogs collection:</strong><br />
                    Easily authenticate via Discogs to gain access to your collection. Browse, sort, and search
                    your collection so you can find the album you just listend to.
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} mb={2}>
                    <strong>3. Ratings and reviews:</strong><br />
                    Rate each album you listen to and leave reviews/notes to capture your thoughts and feelings. Not
                    only does this help you remember your impressions, but it also provides valueable insights for
                    fellow music enthusiasts.
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} mb={2}>
                    <strong>4. Share your music journey</strong><br />
                    Connect with friends and share your musical discoveries. Whether you've stumbled upon a 
                    hidden gem or revisited a classic, your friends can see what you've been listening to and
                    get inspired by your recommendations.
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} mb={2}>
                    <strong>5. Discover new music</strong><br />
                    Look at the community feed to see what others have been listening to. Uncover hidden gems,
                    trending albums, and new releases to keep your musical journey fresh and exciting.
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} mb={2}>
                    Join {process.env.REACT_APP_SITE_NAME} today and embark on a personalized musical journey. Rediscover the joy
                    of tracking your album adventures and sharing the magic of music with friends. Your sound,
                    your story - all in one place. Let the music play, and let {process.env.REACT_APP_SITE_NAME} keep the rhythym
                    of your musical exploration alive!
                </Grid>
            </Grid>

        </div>
    )
}

export default Home;