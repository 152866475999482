import React from 'react';

import './CollectionPagination.scss';

import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button, Grid } from '@mui/material';

interface ICollectionPagination {
    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    pagination: any;
}

const CollectionPagination:React.FunctionComponent<ICollectionPagination> = ({
    page, setPage, pagination
}) => {

    const firstPage = () => {
        setPage(1);
    }

    const nextPage = () => {
        if (page === pagination?.pages) return;

        const nextPage = page +1;
        setPage(nextPage);
    }

    const previousPage = () => {
        if (page === 1) return;

        const previousPage = page -1;
        setPage(previousPage);
    }

    const lastPage = () => {
        setPage(pagination?.pages);
    }

    return (
        <>
            { pagination && <div className='collection-pagination'>
                <Grid container>
                    <Grid item xs={12} lg={12}>
                        
                        <div>
                            <Button onClick={firstPage} sx={{ color: '#1a1a1a'}}
                                title='Go to the first page' className='navigation-button'>
                                <KeyboardDoubleArrowLeftIcon />
                            </Button>
                            <Button onClick={previousPage} sx={{ color: '#1a1a1a'}}
                                title='Go to the previous page' className='navigation-button'>
                                <KeyboardArrowLeftIcon />
                            </Button>
                            <Button onClick={nextPage} sx={{ color: '#1a1a1a'}}
                                title='Go to the next page' className='navigation-button'>
                                <KeyboardArrowRightIcon />
                            </Button>
                            <Button onClick={lastPage} sx={{ color: '#1a1a1a'}}
                                title='Go to the last page' className='navigation-button'>
                                <KeyboardDoubleArrowRightIcon />
                            </Button>
                        </div>
                        
                    </Grid>

                    <Grid item xs={12} lg={12} sx={{ paddingTop: '0.4rem', paddingLeft: '1.4rem'}}>
                        <div className='navigation-page-number'>
                            { pagination && <strong>Page {page} of {pagination?.pages}</strong> }
                        </div>
                    </Grid>

                </Grid>

            </div>}
        </>
    )
};

export default CollectionPagination;