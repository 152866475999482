import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';
import React, { useEffect, useState } from 'react';
import { fixDate } from '../../utils/dates';

import { v4 as uuidv4 } from 'uuid';
import { Grid, IconButton, LinearProgress, Tooltip, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import CustomRating from '../custom-rating/CustomRating';
import { useAppSelector } from '../../redux/hooks';
import { getUser } from '../../redux/sliceUser';

import MusicNoteIcon from '@mui/icons-material/MusicNote';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { gqlLikeListen, gqlUnlikeListen } from '../../gql';
import { gql, useMutation } from '@apollo/client';
import AddNotesDialogButton from '../Notifications/AddNotesDialogButton';
import CustomSnackBar, { ICustomSnackState } from '../custom-snack-bar/CustomSnackBar';

type TimelineLayoutProps = {
    timelineData: any[];
    username: string;
    setTimelineData: React.Dispatch<React.SetStateAction<any[]>> | null;
    setRefetchData?: React.Dispatch<React.SetStateAction<boolean>> | null;
}

const TimelineLayout:React.FunctionComponent<TimelineLayoutProps> = ({ timelineData, username, setTimelineData, setRefetchData }) => {
    const user = useAppSelector((state) => getUser(state));
    const nav = useNavigate();
    
    const [listenAction, setListenAction] = useState({ action: 'like', id: '' });

    const timesUser = username !== '' ? `${username} has` : "You've";

    const likeQuery = gqlLikeListen(listenAction.id);
    const [likeListen, { data:likeData, error:likeError, loading:likeLoading}] = 
        useMutation(gql`${likeQuery.query}`, { variables: likeQuery.variables });
    const unlikeQuery = gqlUnlikeListen(listenAction.id);
    const [unlikeListen, { data:unlikeData, error:unlikeError, loading:unlikeLoading}] = 
        useMutation(gql`${unlikeQuery.query}`, { variables: unlikeQuery.variables });

    const handleLike = (id:string) => {
        setListenAction({ action: 'like', id: id });
    }

    const handleUnlike = (id:string) => {
        setListenAction({ action: 'unlike', id: id });
    }

    useEffect(() => {

        if (listenAction?.action === 'like' && listenAction?.id !== '') {
            likeListen();
        } else if (listenAction?.action === 'unlike' && listenAction?.id !== '') {
            unlikeListen();
        }

    }, [listenAction, likeListen, unlikeListen]);

    useEffect(() => {

        if (likeData || unlikeData) {

            let itemLikes;
            if (listenAction?.action === 'like' && likeData?.likeListen?.likes) {
                itemLikes = likeData?.likeListen?.likes;
            } else if (listenAction?.action === 'unlike' && unlikeData?.unlikeListen?.likes) {
                itemLikes = unlikeData?.unlikeListen?.likes;
            }

            // copy the array
            let tmpData = JSON.parse(JSON.stringify(timelineData));

            // find the listen in the timeline array based on the id
            const aIndex = tmpData?.findIndex((obj: { _id: string; }) => obj?._id === listenAction?.id);

            // if the item was found
            if (aIndex > -1) {
                tmpData[aIndex].likes = itemLikes;

                if (setTimelineData)
                    setTimelineData(tmpData);
            }
        }

        if (likeError)
            console.error(likeError);

        if (unlikeError)
            console.error(unlikeError);
        
        // eslint-disable-next-line
    }, [likeData, unlikeData, likeError, unlikeError]);

    const handleEdit = (item:any) => {
        nav('../timeline/edit', { state: item });
    }

    const handleDelete = (item:any) => {
        nav('../timeline/delete', { state: item });
    }

    // snack bar
    const [snackState, setSnackState] = useState<ICustomSnackState>({ open: false, 
        severity: "success", message: "", hideDuration: 5000 });
    
    return (
        <div>
            <Grid container>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    { (likeLoading || unlikeLoading) && <LinearProgress color="secondary" /> }
                </Grid>
            </Grid>

            <CustomSnackBar setCustomSnackState={setSnackState} state={snackState} />

            <Timeline position="alternate">
                {timelineData?.map(function(item:any) {
                    return (
                        <TimelineItem key={uuidv4()}>
                            <TimelineOppositeContent sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary">
                                {fixDate(item?.timestamp)}

                                {
                                    item?.notes && 
                                    <span>
                                        <br /><em>{item?.notes}</em>
                                    </span>
                                }

                                <Typography>
                                    { item?.likes?.filter((like: { userId: any; }) => { return like?.userId === user?._id  }).length === 1 ?
                                        
                                        <Tooltip arrow title={
                                            <React.Fragment>
                                                <Typography fontSize={12} mb={1}>Liked by</Typography>
                                                <Typography fontSize={12}>
                                                    {item?.likes?.map(function(like:any) {
                                                        return (<div key={uuidv4()}>{like?.username}</div>)
                                                    })}
                                                </Typography>
                                            </React.Fragment>
                                        }>
                                            <span>
                                                <IconButton aria-label="Unlike this listen" size="small" 
                                                    disabled={username === user?.discogsUsername || username === ''}
                                                    onClick={() => handleUnlike(item?._id)}>
                                                    <ThumbUpIcon color="primary" fontSize="inherit" /> 
                                                </IconButton>
                                            </span>
                                        </Tooltip>

                                        : 
                                        <IconButton aria-label="Like this listen" size="small" 
                                            disabled={username === user?.discogsUsername || username === ''}
                                            onClick={() => handleLike(item?._id)}>
                                            <ThumbUpOffAltIcon color="inherit" fontSize="inherit" />
                                        </IconButton>
                                    }  
                                    <Typography variant="caption">
                                        {/* {item?.likes ? item?.likes?.length : 0} {item?.likes?.length === 1 ? 'user' : 'users'} */}
                                        {item?.likes ? item?.likes?.length : 0}
                                    </Typography>

                                    { user?._id === item?.userId && 

                                        <>
                                            <Typography variant="caption" pl={1}>
                                                <IconButton aria-label="Edit this entry" size="small" 
                                                    onClick={() => handleEdit(item)} title="Edit this entry">
                                                    <EditIcon color="inherit" fontSize="inherit" />
                                                </IconButton>
                                            </Typography>

                                            <Typography variant="caption" pl={1}>
                                                <IconButton aria-label="Delete this entry" size="small" 
                                                    onClick={() => handleDelete(item)} title="Delete this entry">
                                                    <DeleteIcon color="inherit" fontSize="inherit" />
                                                </IconButton>
                                            </Typography>
                                        </>

                                    }
                                    
                                </Typography>
                                
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot color="primary">
                                    <MusicNoteIcon />
                                </TimelineDot>
                                <TimelineConnector sx={{ bgcolor: 'secondary.main' }}  />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                
                                <Typography variant="h6" component="span">
                                    <Link to={`../timeline/user/${username ? username : user?.discogsUsername}/${item?.releaseId}`}>{item?.listenedTo?.title}</Link>
                                </Typography>
                                <Typography variant="h6" component="span" ml={1}>
                                    <Link to={`${item?.listenedTo?.uri}`}
                                        target="_blank" title="View this title on Discogs">
                                            <OpenInNewIcon fontSize="small" />
                                    </Link>
                                </Typography>
                                <Typography>{item?.listenedTo?.artists_sort}</Typography>
                                <Typography variant="caption">
                                    {timesUser} listened to this album {item?.listenCount} {(item?.listenCount === 1 ? 'time' : 'times')}
                                </Typography>
                                {
                                    item?.rating && 
                                    <Typography mt={1}>
                                        <CustomRating size="small" 
                                            rating={item?.rating} 
                                            setRating={null} 
                                            readonly={true} 
                                        />
                                    </Typography>
                                }
                                {
                                    (item?.listenedWith && item?.listenedWith?.length > 0) &&
                                    <Typography variant="caption"><br />With:<br />
                                        { item?.listenedWith?.map(function(withUser:any, index:number) {
                                            let prepend = index === 0 ? ` ` : `, `;
                                            const wording = `@${withUser?.username}`;
                                            return (<span key={`${uuidv4()}`}>{prepend}
                                                { user?._id === withUser?.userId &&
                                                    <>
                                                        <AddNotesDialogButton 
                                                            id={item?._id} 
                                                            snackState={snackState}
                                                            setCustomSnackState={setSnackState}
                                                            buttonType='icon'
                                                            setRefetchData={setRefetchData}
                                                        />
                                                    </>
                                                }
                                                <Link to={`../user/${withUser?.username}`}>{wording}</Link> 
                                                <em> {withUser?.notes}</em>
                                                
                                            </span>)
                                        })}
                                    </Typography>
                                }
                            </TimelineContent>
                        </TimelineItem>
                    )
                })}
            </Timeline>
        </div>
    )
}

export default TimelineLayout;