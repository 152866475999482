import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import { NotificationType } from "../enums/NotificationType";

interface Notification {
    _id: string;
    fromUserId: string;
    fromUsername: string;
    fromUserAvatar: string;
    message: string;
    read: boolean;
    readAt: number;
    timestamp: number;
    notificationType: NotificationType;
    releaseId: number;
    releaseName: string;
}

interface NotificationState {
    forceUpdate: boolean;
    notifications: Notification[];
    unreadNotifications: Notification[];
    unreadCount: number;
}

const initialState:NotificationState = {
    forceUpdate: false,
    notifications: [],
    unreadNotifications: [],
    unreadCount: 0
};

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setForceUpdate: (state, action:PayloadAction<any>) => {
            state.forceUpdate = action.payload;
        },
        setUnreadNotifications: (state, action:PayloadAction<any[]>) => {
            state.unreadNotifications = action.payload;
        },
        setUnreadCount: (state, action:PayloadAction<number>) => {
            //console.log("slice setUnreadCount", action.payload);
            state.unreadCount = action.payload;
        },
        setNotifications: (state, action:PayloadAction<any>) => {
            state.notifications = action.payload
        }
    }
})

export const { setUnreadNotifications, setNotifications, setForceUpdate, setUnreadCount } = notificationSlice.actions;

export const getUnreadNotifications = (state: RootState) => state.notifications.unreadNotifications;
export const getNotifications = (state: RootState) => state.notifications.notifications;
export const getForceUpdate = (state: RootState) => state.notifications.forceUpdate;
export const getUnreadCount = (state: RootState) => state.notifications.unreadCount;

export default notificationSlice.reducer;