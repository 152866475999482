import * as gqlbuilder from 'gql-query-builder';

export const gqlSubscriptionNewNotification = () => gqlbuilder.subscription({
    operation: 'getUnreadNotifications',
    fields: ['_id','fromUserId','fromUsername','fromUserAvatar','message','read','readAt',
        'timestamp','notificationType','releaseId','releaseName','listenId'],
    variables: {
        
    }
});
