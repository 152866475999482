import React, { useEffect, useState, useRef, SyntheticEvent } from 'react';

import './Collection.scss';

import { gqlChangeRating, gqlGetUserCollection } from '../../gql';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';

import { Rating, Typography } from '@mui/material';

import { v4 as uuidv4 } from 'uuid';

import Grid from '@mui/material/Grid';

import CustomSnackBar, { ICustomSnackState } from '../custom-snack-bar/CustomSnackBar';
import CollectionMenu from '../CollectionMenu/CollectionMenu';
import { flattenFormats, flattenGenres } from '../../utils/generic';
import CollectionSearch from '../CollectionSearch/CollectionSearch';
import CollectionPagination from '../CollectionPagination/CollectionPagination';
import { setLoading } from '../../redux/sliceLoading';

const Collection:React.FunctionComponent = () => {
    const user = useAppSelector((state) => state.user.value);
    const username = user?.discogsUsername;

    const [page, setPage] = useState(1);
    const [sort, setSort] = useState("added");
    const [order, setOrder] = useState("desc");
    const [search, setSearch] = useState("");
    const [apiData, setApiData] = useState<any>();
    const [folder, setFolder] = useState("0");
    const [ratingInfo, setRatingInfo] = useState<any>({
        folderId: 0,
        releaseId: 0,
        instanceId: 0,
        rating: 0
    });

    const collectionQuery = gqlGetUserCollection(username, page, sort, order, search, folder);
    const { loading, error, data } = useQuery(gql`${collectionQuery.query}`, { 
        variables:collectionQuery.variables,
        fetchPolicy: 'cache-and-network'
    });

    const changeRatingQuery = gqlChangeRating(
        ratingInfo?.folderId, 
        ratingInfo?.releaseId, 
        ratingInfo?.instanceId, 
        ratingInfo?.rating
    );
    const [changeRating, {loading:ratingLoading, error:ratingError, data:ratingData}] = useMutation(gql`${changeRatingQuery.query}`, {
        variables: changeRatingQuery.variables,
        fetchPolicy: 'no-cache'
    })

    const topRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        topRef?.current?.scrollIntoView({behavior: 'smooth'});

        if (data)
            setApiData(data?.getCollection);

        if (error)
            console.error(error);

    }, [data, error]);

    const handleRatingChange = (event: SyntheticEvent<Element, Event>, 
        value: number | null, folderId: number, releaseId: number, instanceId: number) => {

        const rInfo = {
            folderId: folderId,
            releaseId: releaseId,
            instanceId: instanceId,
            rating: value
        }

        setRatingInfo(rInfo);
    }

    useEffect(() => {
        if (ratingInfo?.folderId !== 0 && ratingInfo?.releaseId !== 0 && ratingInfo?.instanceId !== 0)
            changeRating();

    }, [ratingInfo, changeRating])

    const [snackState, setSnackState] = useState<ICustomSnackState>({ open: false, 
        severity: "success", message: "", hideDuration: 5000});

    useEffect(() => {

        if (ratingInfo?.folderId !== 0 && ratingInfo?.releaseId !== 0
            && ratingInfo?.instanceId !== 0 && ratingInfo?.rating !== 0) {
            setSnackState({...snackState, open: true, message: "Rating updated!"});
            let tmpItem = apiData?.releases?.filter((item:any) => {
                return item?.id === ratingInfo?.releaseId
            })[0];
            tmpItem = {...tmpItem, rating: ratingInfo?.rating };
            const tmpReleases = apiData?.releases?.map((t:any) => t.id !== tmpItem.id ? t : tmpItem);
            const newApiData = {...apiData, releases: tmpReleases};
            setApiData(newApiData);

            setRatingInfo({
                folderId: 0,
                releaseId: 0,
                instanceId: 0,
                rating: 0
            });
        }

        if (ratingError)
            console.error(ratingError);

    }, [ratingData, ratingError, setSnackState, ratingInfo, apiData, snackState]);

    // use redux toolkit dispatch
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setLoading(loading));
    }, [loading, dispatch])

    useEffect(() => {
        dispatch(setLoading(ratingLoading));
    }, [ratingLoading, dispatch])
    
    return (
        <div>
            <div ref={topRef}></div>
            <Grid container className='page-header-row' mb={3}>
                <Grid item xs={12}><h1>My Collection <LibraryMusicIcon fontSize='large' /></h1></Grid>
            </Grid>

            <CustomSnackBar setCustomSnackState={setSnackState} state={snackState} />

            <CollectionSearch sort={sort} setSort={setSort}
                order={order} setOrder={setOrder}
                setSearch={setSearch} folder={folder} setFolder={setFolder}
                username={username}
            />
            
            <div className='user-collection'>

                {apiData?.releases?.map(function(item:any, i:number) {
                    return (
                        <Grid container spacing={0} sx={{ paddingBottom: '0.5rem' }} 
                            className='collection-row collection-details-row'
                            key={uuidv4()}>
                            <Grid item xs={3} sm={6} md={1} lg={1}>
                                <img src={item?.basic_information?.thumb} 
                                    alt={item?.basic_information?.title} className='album-image' />
                            </Grid>
                            <Grid item xs={6} sm={6} md={7} lg={7} sx={{ paddingRight: '0.5rem'}}>
                                <strong>{item?.basic_information?.title}</strong>
                                <br />
                                {item?.basic_information?.artists?.map(function(a:any, i:number) {
                                    return (
                                        <span key={a?.id}>{a?.name} </span>
                                    )
                                })}<br />
                                <Rating value={item?.rating}
                                    onChange={(e, v) => handleRatingChange(e, v, item?.folder_id, item?.id, item?.instance_id)}
                                />
                            </Grid>
                            <Grid item xs={3} sm={3} md={4} lg={4} className='listen-button-column'>
                            
                                <CollectionMenu item={item} />
                                
                            </Grid>
                            <Grid item xs={12} mr={1} ml={1}>
                                <Typography variant='caption'>
                                    <em>
                                        Year: {item?.basic_information?.year} |
                                        Format(s): {flattenFormats(item?.basic_information?.formats)} |
                                        Genre(s): {flattenGenres(item?.basic_information?.genres)}
                                    </em>
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                })}
            </div>

            <CollectionPagination page={page} setPage={setPage} pagination={apiData?.pagination} />

        </div>
    )
};

export default Collection;