import React from 'react';

import './CustomRating.scss';

import MusicNoteIcon from '@mui/icons-material/MusicNote';
import MusicNoteOutlinedIcon from '@mui/icons-material/MusicNoteOutlined';
import { Rating } from '@mui/material';

interface CustomRatingProps {
    rating: number;
    setRating: React.Dispatch<React.SetStateAction<number>> | null;
    readonly: boolean;
    size: any;
}

const CustomRating:React.FunctionComponent<CustomRatingProps> = ({ 
    rating, 
    setRating, 
    readonly, 
    size = "medium" 
}) => {

    return (
        <>
            <Rating precision={1}
                readOnly={readonly}
                size={size}
                className='custom-rating'
                // sx={{
                //     '& .MuiRating-iconFilled': {
                //         color: '#278421',
                //     },
                //     '& .MuiRating-iconHover': {
                //         color: '#278421',
                //     }
                // }}
                value={rating}
                onChange={(event, newValue) => {
                    if (setRating && newValue)
                        setRating(newValue);
                }}
                icon={<MusicNoteIcon fontSize='inherit' color='inherit' />}
                emptyIcon={<MusicNoteOutlinedIcon fontSize='inherit' />}
            />
        </>
    )

}

export default CustomRating;