export function flattenArtists(artistsArray:any) {
    let artists = '';
    for (let a = 0; a < artistsArray?.length; a++) {
        artists += artistsArray[a]?.name + ', ';
    }
    artists = artists.slice(0, artists.length - 2);
    return artists;
}

export function flattenFormats(array:any) {
    let ret = '';
    for (let a = 0; a < array?.length; a++) {
        ret += array[a]?.name + ', ';
    }
    ret = ret.slice(0, ret.length - 2);
    return ret;
}

export function flattenFormatsReleases(array:any) {
    let ret = '';
    for (let a = 0; a < array?.length; a++) {
        ret += array[a] + ', ';
    }
    ret = ret.slice(0, ret.length - 2);
    return ret;
}

export function flattenGenres(array:any) {
    let ret = '';
    for (let a = 0; a < array?.length; a++) {
        ret += array[a] + ', ';
    }
    ret = ret.slice(0, ret.length - 2);
    return ret;
}