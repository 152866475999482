import { Badge, Grid, IconButton, Popover, Link, Typography } from '@mui/material';
import React from 'react';

import EmailIcon from '@mui/icons-material/Email';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getUnreadCount, setForceUpdate, setUnreadCount } from '../../redux/sliceNotifications';

import { v4 as uuidv4 } from 'uuid';
import { NotificationType } from '../../enums/NotificationType';
import { fixDate } from '../../utils/dates';
import MarkNotificationReadButton from './MarkNotificationReadButton';
import { gqlSubscriptionNewNotification } from '../../gqlSubscriptions';
import { gql, useQuery, useSubscription } from '@apollo/client';
import { gqlGetNotifications } from '../../gql';

const NotificationPopover:React.FunctionComponent = () => {
    // use redux toolkit dispatch
    const dispatch = useAppDispatch();
    const unreadMessages = useAppSelector((state) => getUnreadCount(state));
    
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const nav = useNavigate();
    const [notificationData, setNotificationData] = React.useState<any[]>([]);
    const id = open ? 'notification-popover' : undefined;

    // on initial load get all messages
    const queryMessages = gqlGetNotifications(false);
    const { error, data } = useQuery(gql`${queryMessages.query}`, { 
        variables: queryMessages.variables,
        fetchPolicy: 'no-cache'
    });

    // after on initial load
    React.useEffect(() => {

        if (data) {
            setNotificationData(data?.getNotifications);

            dispatch(setUnreadCount(data?.getNotifications?.length));
            if (data?.getNotifications?.length === 0)
                setAnchorEl(null);
                
        }
        
        if (error)
            console.error(error);

    }, [data, error, dispatch])

    // subscription based updates
    const queryNotifications = gqlSubscriptionNewNotification();
    const { data:notifData, error:notifError } = useSubscription(gql`${queryNotifications.query}`, {
        fetchPolicy: 'network-only'
    });

    React.useEffect(() => {

        if (notifData) {
            setNotificationData(notifData?.getUnreadNotifications);

            dispatch(setUnreadCount(notifData?.getUnreadNotifications?.length));
            if (notifData?.getUnreadNotifications?.length === 0)
                setAnchorEl(null);
            
            // in case we are on the notifications screen
            // we want that data to refresh to so the user has
            // the most up to date data
            dispatch(setForceUpdate(true));
        }
        
        if (notifError)
            console.error(notifError);
        

    }, [notifData, notifError, dispatch]);

    const handleNotificationClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        // if there are no unread messages, bring user to the notifications full page
        if (notificationData?.length === 0)
            nav('../notifications');
        else
            setAnchorEl(event.currentTarget);
    };

    // this closes the popover
    const handleNotificationClose = () => {
        setAnchorEl(null);
    };

    const gotoLink = (link:string) => {
        setAnchorEl(null);
        nav(link);
    }

    return (
        <>
            <IconButton 
                className="notification-badge" 
                aria-label="view notifications"
                id="notification-button"
                aria-controls={open ? 'notification-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleNotificationClick}
                title="view notifications"
            >
                <Badge badgeContent={unreadMessages} color="primary" showZero={false}>
                    <EmailIcon sx={{ color: "#fff" }} />
                </Badge>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleNotificationClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                
            >

                {notificationData?.map(function(item:any) {
                    return (
                        <Grid container key={uuidv4()} sx={{ pt: 1, pl: 2, pr: 2, pb: 1, width: 500}}>
                            <Grid item md={2}>
                                { item?.fromUserAvatar && 
                                    <img className='avatar-image' alt={`User avatar for ${item?.fromUsername}`} 
                                        style={{ marginRight: '1rem', marginTop: '0.5rem' }}
                                        src={item?.fromUserAvatar} height={30} width={30} />
                                }
                            </Grid>
                            <Grid item md={8}>
                                <Link onClick={() => gotoLink(`../timeline/user/${item?.fromUsername}`)}
                                    underline='hover'
                                    sx={{ cursor: 'pointer'}}
                                >
                                    {item.fromUsername}
                                </Link>

                                { item?.notificationType === NotificationType.LISTENED_WITH && 
                                    ` tagged you in a listening session for ${item?.releaseName}`}
                                { item?.notificationType === NotificationType.LIKED && 
                                    ` liked that you listened to ${item?.releaseName}`}
                                { item?.notificationType === NotificationType.FOLLOWED && 
                                    ` started following you`}
                                { item?.notificationType === NotificationType.NOTES_ADDED && 
                                    ` added notes to your listening session for ${item?.releaseName}`}
                                <br />
                                <Typography variant='caption'>{fixDate(item?.timestamp)}</Typography>
                            </Grid>
                            <Grid item md={2}>
                                <MarkNotificationReadButton
                                    id={item?._id}
                                    setNotificationData={setNotificationData}
                                />
                            </Grid>
                        </Grid>
                    )
                })}
                <Grid container sx={{ pt: 1, pl: 2, pr: 2, pb: 1, width: 500}}>
                    <Grid item md={2}></Grid>
                    <Grid item md={10}>
                        <Typography variant='caption'>
                            <Link onClick={() => gotoLink('../notifications')} 
                                sx={{ cursor: 'pointer' }}
                                underline='hover'
                            >
                                View all notifications
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Popover>
        </>
    )
}

export default NotificationPopover;

