import { gql, useLazyQuery } from '@apollo/client';
import React from 'react';
import { gqlGetDiscogsRelease } from '../../gql';

import noImage from '../../assets/no_image.jpg';
import { Backdrop, Box, Button, CircularProgress, IconButton, LinearProgress, Link, MobileStepper, Paper, useTheme } from '@mui/material';

import './AlbumImages.scss';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

import CloseIcon from '@mui/icons-material/Close';

interface IAlbumImages {
    id: number;
    title: string;
    thumb: string;
}

const AlbumImages:React.FunctionComponent<IAlbumImages> = ({ id, thumb, title }) => {
    // https://mui.com/material-ui/react-stepper/#text-with-carousel-effect

    const releaseQuery = gqlGetDiscogsRelease(id);
    const [getRelease, { loading, error, data}] = useLazyQuery(gql`${releaseQuery.query}`, {
        variables: releaseQuery?.variables
    });

    const [images, setImages] = React.useState<any>();

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    React.useEffect(() => {

        if (data) {
            setImages(data?.getDiscogsRelease?.release?.images);
        }

        if (error) {
            console.error(error);
        }
    }, [error, data])

    const handleClick = () => {
        getRelease();
        setOpen(true);
    }

    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images ? images?.length : 0;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const backdropRef = React.useRef();

    React.useEffect(() => {
        function handleKeyDown(e: { keyCode: any; }) {
            //console.log(e.keyCode);
            if (e.keyCode === 27) {
                setOpen(false);
            } 
          }
      
          document.addEventListener('keydown', handleKeyDown);
      
          // Don't forget to clean up
          return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
          }
      }, []);

    return (
        <>
            { thumb ? 
                <Link component={'button'} onClick={handleClick}>
                    <img src={`${thumb}`} width={75} alt={`${title}`} title={`Album thumbnail for ${title}`} />
                </Link>
                :
                <img src={`${noImage}`} width={75} alt={'Album placeholder'} title={'No Album image'} />
            }
            <div className='images-loading'>{ loading && <LinearProgress /> }</div>
            
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                // onClick={handleClose}
                ref={backdropRef}
            >
                <Box sx={{ width: '50%',  height: '85%', maxWidth: 500, flexGrow: 1 }}>
                    <Paper
                        square
                        elevation={0}
                        sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: 50,
                        pl: 2,
                        bgcolor: 'background.default',
                        }}
                    >
                        {/* <Typography>{activeStep}</Typography> */}
                        <IconButton aria-label="close" onClick={handleClose} title='Close'>
                            <CloseIcon />
                        </IconButton>
                    </Paper>

                    <div className='images-container'>
                        { loading && <CircularProgress /> }
                        { images && <img src={images[activeStep]?.uri} alt={`album art`} /> }
                    </div>
                    
                    <MobileStepper
                        steps={maxSteps}
                        position="static"
                        activeStep={activeStep}
                        nextButton={
                        <Button
                            size="small"
                            onClick={handleNext}
                            disabled={activeStep === maxSteps - 1}
                        >
                            Next
                            {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                            ) : (
                            <KeyboardArrowRight />
                            )}
                        </Button>
                        }
                        backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                            ) : (
                            <KeyboardArrowLeft />
                            )}
                            Back
                        </Button>
                        }
                    />
                    </Box>

            </Backdrop>

        </>
    )
}

export default AlbumImages;