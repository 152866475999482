import React, { useEffect, useState } from 'react';

import './TimelineDelete.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Grid } from '@mui/material';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { LoadingButton } from '@mui/lab';
import { gqlDeleteListen } from '../../gql';
import { gql, useMutation } from '@apollo/client';
import CustomSnackBar, { ICustomSnackState } from '../custom-snack-bar/CustomSnackBar';
import TimelineEntryLayout from '../TimelineEntryLayout/TimelineEntryLayout';
import { useAppSelector } from '../../redux/hooks';

dayjs.extend(utc);

const TimelineDelete:React.FunctionComponent = () => {
    const data = useLocation();
    const item = data?.state;
    const nav = useNavigate();
    const user = useAppSelector((state) => state.user.value);
    const [listendWith] = React.useState<any[]>(item?.listenedWith);
    const [rating] = useState(item?.rating);

    const deleteListenMutation = gqlDeleteListen(item?._id);
    const [deleteListen, { loading:deleteLoading, error:deleteError, data:deleteData }] = useMutation(
        gql`${deleteListenMutation.query}`, { variables: deleteListenMutation.variables, errorPolicy: 'all'}
    )

    const [snackState, setSnackState] = useState<ICustomSnackState>({ open: false, 
        severity: "success", message: "", hideDuration: 5000});

    const handleCancel = () => {
        nav('../../timeline');
    }

    const handleDeleteEntry = () => {
        deleteListen();
    }

    useEffect(() => {
        if (deleteData?.deleteListen?.success) {
            setSnackState({ ...snackState, open: true, severity: "success", message: "Timeline entry deleted!"});
            setTimeout(() => { nav('../../timeline') }, 1500);
        }

        if (deleteError) {
            setSnackState({ ...snackState, open: true, severity: "error", message: "There was an error deleting this timeline entry"});
            console.error(deleteError);
        }
         
    }, [deleteData, deleteError, nav]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>

            <CustomSnackBar setCustomSnackState={setSnackState} state={snackState} />

            <Grid container className='page-header-row' mb={3}>
                <Grid item xs={12}><h1>Delete timeline entry</h1></Grid>
            </Grid>

            <TimelineEntryLayout user={user} title={item?.listenedTo?.title} thumb={item?.listenedTo?.thumb} 
                artists={item?.listenedTo?.artists_sort} notes={item?.notes} setNotes={null} 
                datetime={dayjs(item?.timestamp * 1000)} setDatetime={null} readonly={true}
                listenedWith={listendWith} setListenedWith={null} 
                rating={rating} setRating={null} />

            <Grid container mb={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                <LoadingButton variant="contained" size="large" color='error'
                        sx={{ width: { xs: '100%', md: 'auto' },
                            marginBottom: '1rem', marginRight: { xs: '0rem', md: '1rem'} }}
                        loading={deleteLoading}
                        disabled={deleteData?.deleteListen?.success}
                        onClick={handleDeleteEntry} title='Delete entry'>Delete entry</LoadingButton>

                    <Button variant="outlined" size="large"
                        sx={{ width: { xs: '100%', md: 'auto'}, marginBottom: '1rem', marginRight: { xs: '0rem', md: '1rem'} }}
                        onClick={handleCancel}>
                        Cancel
                    </Button>

                </Grid>
            </Grid>

        </div>
    )
}

export default TimelineDelete;