import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Auth0Provider } from '@auth0/auth0-react';

// Bootstrap CSS
//import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
//import "bootstrap/dist/js/bootstrap.bundle.min";
import ApolloConnection from './apolloConnection';

import { Provider } from "react-redux";
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';

import "./scss/custom.scss";

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Auth0Provider 
      domain={`${domain}`}
      clientId={`${clientId}`}
      authorizationParams={{
        ignoreCache: true,
        redirect_uri: window.location.origin,
        audience: audience,
        scope: "openid profile email read:listens"
      }}
      useRefreshTokens={true}
      cacheLocation='localstorage'
      useRefreshTokensFallback={true}
    >

      <Provider store={store}>
        <ApolloConnection>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </ApolloConnection>
      </Provider>
      
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
