import React from 'react';

import './Footer.scss';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';

import logo from '../../img/logoskull.png';


const Footer:React.FunctionComponent = () => {

    return (
        <div className='footer-content-container' >
            <div className='footer-container-image' style={{ 
                backgroundImage: `linear-gradient(rgba(255,255,255,.6), rgba(255,255,255,.6)), url(${logo})`,
                backgroundSize: 'cover',
                backgroundPosition: '0 60%',
                backgroundRepeat: 'no-repeat',
                
            }}>
                <Grid container>
                    <Grid item xs={12} className='footer-content-row'>

                        <Grid container>

                            <Grid item xs={12} md={3} className="footer-menu-container">
                                <Grid container>
                                    <Grid item xs={12} mb={3}>
                                        <strong>Customer Service</strong>
                                    </Grid>
                                    <Grid item xs={12} mb={2}>
                                        <Link to={'../about'}>About Us</Link>
                                    </Grid>
                                    <Grid item xs={12} mb={2}>
                                        <Link to={'../contact'}>Contact Us</Link>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Link to={'../policies/privacy'}>Privacy Policy</Link>
                                    </Grid>
                                </Grid>

                            </Grid>

                        </Grid>

                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default Footer;