import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';

import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { gqlMarkNotificationRead } from '../../gql';
import { gql, useMutation } from '@apollo/client';
import { getCurrentTimestamp } from '../../utils/dates';

import { useAppDispatch } from '../../redux/hooks';
import { setForceUpdate } from '../../redux/sliceNotifications';
import { setLoading } from '../../redux/sliceLoading';

interface IMarkNotificationReadButton {
    id: string;
    setNotificationData: React.Dispatch<React.SetStateAction<any[]>> | null;
}

const MarkNotificationReadButton:React.FunctionComponent<IMarkNotificationReadButton> = ({ 
    id, setNotificationData
}) => {

    // use redux toolkit dispatch
    const dispatch = useAppDispatch();
    
    const [notificationQueryData, setNotificationQueryData] = useState<any>({ id: '', timestamp: 0});
    
    // for marking the notification as read
    // popover marks as read then gets only unread messages
    // full screen marks as read and then gets all messages
    const queryMarkRead = gqlMarkNotificationRead(notificationQueryData.id, notificationQueryData.timestamp, null);
    const [markNotification, { loading, error, data}] = useMutation(
        gql`${queryMarkRead.query}`, { 
            variables: queryMarkRead.variables,
            fetchPolicy: 'no-cache'
        }
    )
    
    // sets the query data for marking a notification as being read
    const markNotificationRead = (id:string) => {
        setNotificationQueryData({ id: id, timestamp: getCurrentTimestamp() });
    }

    // happens after the data is set for the notification to be marked as read
    useEffect(() => {

        if (notificationQueryData?.id !== '') {
            markNotification();
        }

    }, [notificationQueryData, markNotification]);

    // after the mutation happens to mark the notification as being read
    useEffect(() => {

        if (data) {

            if (setNotificationData) {
                setNotificationData(data?.markNotificationRead);
                setNotificationQueryData({ id: '', timestamp: 0 });
            }

            dispatch(setForceUpdate(true));
        }

        if (error)
            console.error(error);

    }, [data, error, dispatch, setNotificationData]);

    // use redux toolkit dispatch
    useEffect(() => {
        dispatch(setLoading(loading));
    }, [loading, dispatch])

    return (
        <>
            <IconButton aria-label="mark as read" title='Mark as read'
                onClick={() => markNotificationRead(id)}>
                <MarkEmailReadIcon />
            </IconButton>
        </>
    )
}

export default MarkNotificationReadButton;