import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";

interface DiscogsState {
    oauth_token:string;
    oauth_secret:string;
    oauth_verifier:string;
}

const initialState:DiscogsState = {
    oauth_token: '',
    oauth_secret: '',
    oauth_verifier: ''
};

const discogsSlice = createSlice({
    name: 'discogs',
    initialState,
    reducers: {
        setDiscogsToken: (state, action:PayloadAction<any>) => {
            //console.log("setting discogs token", action.payload);
            state.oauth_token = action.payload;
        },
        setDiscogsSecret: (state, action:PayloadAction<any>) => {
            //console.log("setting discogs secret", action.payload);
            state.oauth_secret = action.payload;
        },
        setDiscogsVerifier: (state, action:PayloadAction<any>) => {
            //console.log("setting discogs verifier", action.payload);
            state.oauth_verifier = action.payload;
        },
        clearDiscogsInfo: (state) => {
            state.oauth_token = '';
            state.oauth_secret = '';
            state.oauth_verifier = '';
        },
        setDiscogsInfo: (state, action:PayloadAction<DiscogsState>) => {
            state.oauth_token = action.payload.oauth_token;
            state.oauth_secret = action.payload.oauth_secret;
            state.oauth_verifier = action.payload.oauth_verifier;
        }
    }
})

export const { setDiscogsToken, setDiscogsSecret, setDiscogsVerifier, clearDiscogsInfo, setDiscogsInfo } = discogsSlice.actions;

export const getDiscogsInfo = (state: RootState) => { 
    return { 
        oauth_token: state.discogs.oauth_token,
        oauth_secret: state.discogs.oauth_secret,
        oauth_verifier: state.discogs.oauth_verifier
    }
}

export const isDiscogsAuthorized = (state: RootState) => {
    if (state.discogs.oauth_token !== undefined && state.discogs.oauth_token !== ''
            && state.discogs.oauth_secret !== undefined && state.discogs.oauth_secret !== ''
            && state.discogs.oauth_verifier !== undefined && state.discogs.oauth_verifier !== '')
        return true;
    else 
        return false;
}

export default discogsSlice.reducer;