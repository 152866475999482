import { Button, Link, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface IMenuItem {
    id: number;
    link: string;
    name: string;
    title: string;
}

interface IMenuGroupButton {
    menuTitle: string;
    menuItems: IMenuItem[];
}

const MenuGroupButton:React.FunctionComponent<IMenuGroupButton> = ({ menuTitle, menuItems }) => {
    //const nav = useNavigate();

    // my menu stuff
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // const handleMenuItemChoice = (to: string) => {
    //     setAnchorEl(null);
    //     nav(to);
    // }

    return (
        <>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{ color: '#ffffff' }}
            >
                {menuTitle}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {menuItems?.map((item:any) => (
                    <MenuItem key={item.id} onClick={handleClose}>
                        <Link component={RouterLink} to={`${item?.link}`} title={item?.name}>{item.name}</Link>
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}

export default MenuGroupButton;