import React, { useEffect, useState } from "react";

import './Discogs.scss';
import { gqlRequestDiscogsAccess, gqlRequestDiscogsToken } from "../../gql";
import { useLazyQuery, gql } from "@apollo/client";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, CircularProgress, Grid } from "@mui/material";

import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { setDiscogsToken, setDiscogsSecret, setDiscogsVerifier } from '../../redux/sliceDiscogs';

const Discogs:React.FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate();

    // init var
    const [searchParams] = useSearchParams();
    const [authFlowComplete, setAuthFlowComplete] = useState(false);
    
    const [userIsSignedIn, setUserIsSignedIn] = useState(false);

    const token = useAppSelector((state) => state?.discogs?.oauth_token);
    const secret = useAppSelector((state) => state?.discogs?.oauth_secret);
    const verifier = useAppSelector((state) => state?.discogs?.oauth_verifier);

    // gql
    const requestQuery = gqlRequestDiscogsToken();
    const accessQuery = gqlRequestDiscogsAccess(token, secret, verifier);

    // queries
    const [authDiscogs, { loading:authLoading, error:authError, data:authData}] = 
        useLazyQuery(gql`${requestQuery.query}`);
    const [accessDiscogs, { loading:accessLoading, error:accessError, data:accessData}] = 
        useLazyQuery(gql`${accessQuery.query}`, { variables: accessQuery.variables});

    useEffect(() => {

        if (token !== undefined && token !== ''
            && secret !== undefined && secret !== ''
            && verifier !== undefined && verifier !== '') {
            console.log("user is signed in");
            setAuthFlowComplete(true);
            setUserIsSignedIn(true);
            navigate('../discogs/profile');
        } else if (authFlowComplete === false) {

            console.log("user needs to authenticate with discogs");
            
            // determine if the user cancelled or progressed
            const wasDenied = searchParams.get('denied');
            const oauthToken = searchParams.get('oauth_token');
            const oauthVerifier = searchParams.get('oauth_verifier');

            if (wasDenied) {
                // user denied the authentication request
                console.log("user denied", wasDenied);
            } else if (oauthToken && oauthVerifier) {
                
                // set token verifier
                if (oauthVerifier && oauthVerifier !== '')
                    dispatch(setDiscogsVerifier(oauthVerifier));

            } 
        } else {
            //console.log("AUTH FLOW COMPLETE");
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // step1 - call graphql to get the token
    const step1getToken = () => {
        // call graphql to get the token
        console.log("AUTHENTICATE STEP 1 - START");
        authDiscogs();
    }

    // step2 - get token then redirect
    useEffect(() => {
        
        if (authData) {
            console.log("AUTHENTICATE STEP 2");

            if (authData?.requestToken?.oauth_token && authData?.requestToken?.oauth_token !== '')
                dispatch(setDiscogsToken(authData?.requestToken?.oauth_token));

            if (authData?.requestToken?.oauth_token_secret && authData?.requestToken?.oauth_token_secret !== '')
                dispatch(setDiscogsSecret(authData?.requestToken?.oauth_token_secret));

            const discogsUrl = `${process.env.REACT_APP_DISCOGS_OAUTH_TOKEN_URL}${authData?.requestToken?.oauth_token}`;
            window.location.href = discogsUrl;
        }

    }, [authData, authError, dispatch])

    // step3 - get access token
    useEffect(() => {
        
        if (token !== undefined && token !== '' &&
            secret !== undefined && secret !== '' && 
            verifier !== undefined && verifier !== '' && 
            authFlowComplete === false) {
            console.log("AUTHENTICATE STEP 3");
            accessDiscogs();
        }

    }, [token, secret, verifier, authFlowComplete, accessDiscogs])

    // step4 - access token received; authenticated
    useEffect(() => {

        // retrieved access token from discogs, auth flow is complete
        if (accessData?.accessToken) {
            console.log("AUTHENTICATE STEP 4 - COMPLETE");

            if (accessData?.accessToken?.oauth_token && accessData?.accessToken?.oauth_token !== '')
                dispatch(setDiscogsToken(accessData?.accessToken?.oauth_token)); 

            if (accessData?.accessToken?.oauth_token_secret && accessData?.accessToken?.oauth_token_secret !== '')
                dispatch(setDiscogsSecret(accessData?.accessToken?.oauth_token_secret));

            setAuthFlowComplete(true);
            navigate('../discogs/identity');
        } 

        if (accessError)
            console.error(accessError);

    }, [accessData, accessError, navigate, dispatch])

    return (
        <div>
            
            <Grid container className='page-header-row'>
                <Grid item xs={12}><h1>Discogs Authentication</h1></Grid>
            </Grid>

            { (authLoading || accessLoading) && <CircularProgress /> }

            <Grid container className="spacer-row"></Grid>

            {
                !userIsSignedIn &&
                <>
                    <Grid container>
                        <Grid item xs={12} mb={2}>
                            Click the button below to intialize the discogs authentication flow
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <Button variant="contained" onClick={step1getToken}>Discogs Authentication</Button>
                        </Grid>
                    </Grid>
                </>
            }

        </div>
    )
}

export default Discogs;