import React, { useEffect, useRef, useState } from 'react';

import './UserQueue.scss';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';

import QueueIcon from '@mui/icons-material/Queue';
import { gqlUserQueue } from '../../gql';
import { gql, useQuery } from '@apollo/client';

import { v4 as uuidv4 } from 'uuid';
import { flattenArtists } from '../../utils/generic';

import MusicNoteIcon from '@mui/icons-material/MusicNote';
import { useNavigate } from 'react-router-dom';
import RemoveFromQueueButton from '../RemoveFromQueueButton/RemoveFromQueueButton';
import CustomSnackBar, { ICustomSnackState } from '../custom-snack-bar/CustomSnackBar';
import { fixDate } from '../../utils/dates';
import { useAppDispatch } from '../../redux/hooks';
import { setLoading } from '../../redux/sliceLoading';

const UserQueue:React.FunctionComponent = () => {
    const navigate = useNavigate();
    const topRef = useRef<null | HTMLDivElement>(null);
    const [apiData, setApiData] = useState<any[]>([]);

    const queueQuery = gqlUserQueue();
    const { loading, error, data } = useQuery(gql`${queueQuery.query}`, {
        fetchPolicy: 'no-cache'
    });

    useEffect(() => {

        if (data) {
            //console.log(data?.getQueue);
            setApiData(data?.getQueue);
        }

        if (error) {
            console.error(error);
        }

    }, [data, error])

    const handleAddListen = (props:any) => {
        navigate(`../timeline/add/${props?.releaseId}`, { state: { removeFromQueueId: props?._id, fromQueue: true } });
    }

    const [snackState, setSnackState] = useState<ICustomSnackState>({ open: false, 
        severity: "success", message: "", hideDuration: 5000});

    // use redux toolkit dispatch
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setLoading(loading));
    }, [loading, dispatch])
    
    return (
        <div className='user-queue-container'>
            
            <div ref={topRef}></div>
            <Grid container className='page-header-row' mb={3}>
                <Grid item xs={12}><h1>My Queue <QueueIcon fontSize='large' /></h1></Grid>
            </Grid>

            <CustomSnackBar setCustomSnackState={setSnackState} state={snackState} />

            { apiData?.length === 0 &&
                <Grid container>
                    <Grid item xs={12} className='empty-queue'>
                        <Typography>You currently have nothing in your queue ಥ_ಥ </Typography>
                    </Grid>
                </Grid>
            }

            <Grid container>
                {apiData?.map((item:any) => (

                    <Grid item xs={12} sm={12} md={4} key={uuidv4()}>
                        <Card sx={{ marginBottom: '3rem', marginRight: '1rem', marginLeft: '1rem'}}>
                            <CardContent>
                                <Typography variant="h5" component="div" textAlign={'center'} pb={2}>
                                    {item?.basic_information?.title}
                                </Typography>
                                <Typography component="div" textAlign={'center'} pb={2}>
                                    { item?.basic_information && <img src={item?.basic_information?.thumb} alt={`Cover for ${item?.basic_information?.title}`} /> }
                                </Typography>
                                <Typography component={'div'} textAlign={'center'} pb={2}>
                                    { item?.basic_information && <span><strong>Artist(s):</strong><br /> {flattenArtists(item?.basic_information?.artists)}</span> }
                                </Typography>
                                <Typography component={'div'} textAlign={'center'} pb={1}>
                                    <Button variant='contained' startIcon={<MusicNoteIcon />}
                                        onClick={() => handleAddListen(item)} 
                                        sx={{ marginRight: '0.5rem', width: { xs: '50%', md: 'auto' } }}>
                                        Add
                                    </Button>

                                    <RemoveFromQueueButton id={item?._id} 
                                        setData={setApiData}
                                        setSnackState={setSnackState}
                                    />
                                </Typography>
                                <Typography component={'div'} textAlign={'center'} pb={0} variant='caption'>
                                    <em>Added On: { fixDate(item?.timestamp) }</em>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                ))}
            </Grid>

        </div>
    )
}

export default UserQueue;