import React, { useEffect, useRef, useState } from 'react';

import './Suggest.scss';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';

import ShuffleIcon from '@mui/icons-material/Shuffle';
import MusicNoteIcon from '@mui/icons-material/MusicNote';

import { gqlGetRandomUnplayed } from '../gql';
import { gql, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

import { v4 as uuidv4 } from 'uuid';
import { flattenArtists } from '../utils/generic';
import { useAppDispatch } from '../redux/hooks';
import { setLoading } from '../redux/sliceLoading';
import CustomSnackBar, { ICustomSnackState } from '../components/custom-snack-bar/CustomSnackBar';
import AddToQueueButton from '../components/AddToQueueButton/AddToQueueButton';

const Suggest:React.FunctionComponent = () => {
    const topRef = useRef<null | HTMLDivElement>(null);
    const navigate = useNavigate();

    const query = gqlGetRandomUnplayed("0");
    const { loading, data, error, refetch} = useQuery(gql`${query.query}`, {
        variables: query.variables,
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    });

    const [releases, setReleases] = useState<any>([]);
    const [snackState, setSnackState] = useState<ICustomSnackState>({ open: false, 
        severity: "success", message: "", hideDuration: 5000});

    useEffect(() => {

        if (data) {
            setReleases(data?.getRandomUnplayed?.releases);
        }

        if (error)
            console.error(error);

    }, [data, error]);

    const handleRefetch = () => {
        refetch();
    }

    const handleAddListen = (props:any) => {
        navigate('../timeline/add', { state: props });
    }

    // use redux toolkit dispatch
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setLoading(loading));
    }, [loading, dispatch])
    
    return (
        <>

            <div ref={topRef}></div>
            <Grid container className='page-header-row' mb={3}>
                <Grid item xs={12}><h1>Suggest An Album <ShuffleIcon fontSize="large" /></h1></Grid>
            </Grid>

            <CustomSnackBar setCustomSnackState={setSnackState} state={snackState} />

            <Grid container>
                {releases?.map((item:any) => (

                    <Grid item xs={12} sm={12} md={4} key={uuidv4()}>
                        <Card sx={{ marginBottom: '3rem', marginRight: '1rem', marginLeft: '1rem'}}>
                            <CardContent>
                                <Typography variant="h5" component="div" textAlign={'center'} pb={2}>
                                    {item?.basic_information?.title}
                                </Typography>
                                <Typography component="div" textAlign={'center'} pb={2}>
                                    { item?.basic_information && <img src={item?.basic_information?.thumb} alt={`Cover for ${item?.basic_information?.title}`} /> }
                                </Typography>
                                <Typography component={'div'} textAlign={'center'} pb={2}>
                                    { item?.basic_information && <span><strong>Artist(s):</strong><br /> {flattenArtists(item?.basic_information?.artists)}</span> }
                                </Typography>
                                <Typography component={'div'} textAlign={'center'} pb={1}>
                                    <Button variant='contained' startIcon={<MusicNoteIcon />}
                                        onClick={() => handleAddListen(item)} 
                                        sx={{ 
                                            marginRight: '0.25rem', 
                                            marginBottom: { xs: '1rem', md: '0rem' },
                                            width: { xs: '70%', md: 'auto' }
                                            
                                        }}>
                                        Add
                                    </Button>

                                    <AddToQueueButton id={item?.basic_information?.id} setCustomSnackState={setSnackState} />
                                    
                                    
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                ))}
            </Grid>

            <Grid container>

                <Grid item xs={12} textAlign={'center'}>
                    <LoadingButton variant="outlined" loading={loading} 
                        color='primary'
                        sx={{ width: { xs: '92%', md: 'auto' }}}
                        loadingIndicator='Suggesting...'
                        size="large" 
                        onClick={handleRefetch}>
                        More suggestions
                    </LoadingButton>
                </Grid>

            </Grid>
        
        </>
    )
}

export default Suggest;