import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import { Row, Col, Input, Button } from 'reactstrap';

import './TimelineAdd.scss';
import { gqlAddListen, gqlGetDiscogsRelease } from '../../gql';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import CustomSnackBar, { ICustomSnackState } from '../custom-snack-bar/CustomSnackBar';
import TimelineEntryLayout from '../TimelineEntryLayout/TimelineEntryLayout';

import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { flattenArtists } from '../../utils/generic';
import { setLoading } from '../../redux/sliceLoading';
dayjs.extend(utc);

const TimelineAdd:React.FunctionComponent = () => {
    let data = useLocation();
    const nav = useNavigate();
    const params = useParams();
    const user = useAppSelector((state) => state.user.value);
    const topRef = React.useRef<null | HTMLDivElement>(null);

    const [notes, setNotes] = useState('');
    const [datetime, setDatetime] = useState<Dayjs | null>(dayjs(new Date()));
    const [listendWith, setListenedWith] = useState<any[]>([]);
    const [rating, setRating] = useState(0);
    const [removeFromQueue, setRemoveFromQueue] = useState(true);

    const [apiData, setApiData] = useState<any>();

    // determine if this is coming from the user's queue
    const fromQueue = data?.state?.fromQueue;

    // get the id from the url params
    const id = parseInt(params?.id!);

    // if removing from the queue
    let removeFromQueueId = '';
    if (removeFromQueue)
        removeFromQueueId = data?.state?.removeFromQueueId;

    // get release
    const queryRelease = gqlGetDiscogsRelease(id);
    const { loading:releaseLoading, error:releaseError, data:releaseData } = useQuery(gql`${queryRelease.query}`, {
        variables: queryRelease.variables
    });

    // upon getting release
    useEffect(() => {

        if (releaseData) {
            setApiData(releaseData?.getDiscogsRelease)
        }

        if (releaseError)
            console.error(releaseError);

    }, [releaseData, releaseError])

    // adding the listen
    const addListenMutation = gqlAddListen(id, notes, dayjs.utc(datetime).utc().unix(), rating, listendWith, removeFromQueueId);
    const [addListen, { loading, error, data:addData }] = useMutation(
        gql`${addListenMutation.query}`, { variables: addListenMutation.variables}
    )

    // trigger the adding of a listen
    const handleAddListen:React.MouseEventHandler = async () => {
        addListen();
    }

    // snackbar
    const [snackState, setSnackState] = useState<ICustomSnackState>({ open: false, 
        severity: "success", message: "", hideDuration: 5000});
        
    useEffect(() => {
        if (addData?.addListen?.success) {
            setSnackState({ ...snackState, open: true, severity: "success", message: "Timeline entry added!"});
            setTimeout(() => { nav('../../timeline') }, 1500);
        }

        if (error) {
            setSnackState({ ...snackState, open: true, severity: "error", message: "There was an error adding this timeline entry"});
            console.error(error);
        }

    }, [addData, error, nav]) // eslint-disable-line react-hooks/exhaustive-deps

    // handle cancelling of the add
    const handleCancel = () => {
        nav('../../collection');
    }

    // use redux toolkit dispatch
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setLoading(loading));
    }, [loading, dispatch])

    useEffect(() => {
        dispatch(setLoading(releaseLoading));
    }, [releaseLoading, dispatch])

    useEffect(() => {
        topRef?.current?.scrollIntoView({behavior: 'smooth'});
    }, [])

    return (
        <div>

            <CustomSnackBar setCustomSnackState={setSnackState} state={snackState} />

            <div ref={topRef}></div>
            <Grid container className='page-header-row' mb={3}>
                <Grid item xs={12}><h1>Add to your timeline</h1></Grid>
            </Grid>

            <TimelineEntryLayout user={user} title={apiData?.release?.title} thumb={apiData?.release?.thumb} 
                artists={flattenArtists(apiData?.release?.artists)} notes={notes} setNotes={setNotes} 
                datetime={datetime} setDatetime={setDatetime}
                listenedWith={listendWith} setListenedWith={setListenedWith}
                rating={rating} setRating={setRating} fromQueue={fromQueue} 
                removeFromQueue={removeFromQueue} setRemoveFromQueue={setRemoveFromQueue}
                />

            <Grid container mb={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <LoadingButton variant="contained" size="large"
                        disabled={addData?.addListen?.success}
                        sx={{ width: { xs: '100%', md: 'auto' },
                            marginBottom: '1rem', marginRight: { xs: '0rem', md: '1rem'} }}
                        loading={loading}
                        onClick={handleAddListen} title='Add to timeline'>Add to my timeline</LoadingButton>
                    <Button variant="outlined" size="large"
                        sx={{ width: { xs: '100%', md: 'auto'}, marginBottom: '1rem', marginRight: { xs: '0rem', md: '1rem'} }}
                        onClick={handleCancel}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>

        </div>
    )
}

export default TimelineAdd;