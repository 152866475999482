import React, { useEffect, useRef } from 'react';

import './AdminContactMessages.scss';
import { gqlContactMessagesGetAll } from '../../../gqlAdmin';
import { gql, useQuery } from '@apollo/client';

import EmailIcon from '@mui/icons-material/Email';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { useAppDispatch } from '../../../redux/hooks';
import { setLoading } from '../../../redux/sliceLoading';
import { setAlert } from '../../../redux/sliceAlert';

import { v4 as uuidv4 } from 'uuid';
import { fixDate } from '../../../utils/dates';

const AdminContactMessages:React.FunctionComponent = (props) => {
    const topRef = useRef<null | HTMLDivElement>(null);
    const queryGetMessages = gqlContactMessagesGetAll();
    const {loading, error, data} = useQuery(gql`${queryGetMessages.query}`, {
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
    });

    // use redux toolkit dispatch
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setLoading(loading));
    }, [loading, dispatch])

    useEffect(() => {

        if (data?.getContactMessages) {
            console.log(data?.getContactMessages);
        }

        if (error) {
            dispatch(setAlert({ severity: 'error', message: error.message, show: true }))
            console.error(error);
        }

    }, [data, error, dispatch])

    return (
        <>
            <div ref={topRef}></div>
            <Grid container className='page-header-row' mb={3}>
                <Grid item xs={12}><h1>Contact Messages <EmailIcon fontSize='large' /></h1></Grid>
            </Grid>

            <Grid container>
                {data?.getContactMessages?.map((item:any) => (

                    <Grid item xs={12} sm={12} md={12} key={uuidv4()}>
                        <Card sx={{ marginBottom: '2rem', marginRight: '1rem', marginLeft: '1rem'}}>
                            <CardContent>
                                <Grid container>

                                    <Grid item md={2}>
                                        <Typography component="div" textAlign={'left'} pb={1}>
                                            <strong>From:</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item md={10}>
                                        <Typography component="div" textAlign={'left'} pb={1}>
                                            {item?.fromEmail}
                                            { item?.fromUser?.discogsUsername && <> ({item?.fromUser?.discogsUsername})</>}
                                        </Typography>
                                    </Grid>

                                    <Grid item md={2}>
                                        <Typography component="div" textAlign={'left'} pb={1}>
                                            <strong>UserId:</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item md={10}>
                                        <Typography component="div" textAlign={'left'} pb={1}>
                                            {item?.fromUser?.userId}
                                        </Typography>
                                    </Grid>


                                    <Grid item md={2}>
                                        <Typography component="div" textAlign={'left'} pb={1}>
                                            <strong>Subject:</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item md={10}>
                                        <Typography component="div" textAlign={'left'} pb={1}>
                                            {item?.subject}
                                        </Typography>
                                    </Grid>

                                    <Grid item md={2}>
                                        <Typography component="div" textAlign={'left'} pb={1}>
                                           <strong>Body:</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item md={10}>
                                        <Typography component="div" textAlign={'left'} pb={1}>
                                            {item?.body}
                                        </Typography>
                                    </Grid>

                                    <Grid item md={2}>
                                        <Typography component="div" textAlign={'left'} pb={0}>
                                            <strong>Sent On:</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item md={10}>
                                        <Typography component="div" textAlign={'left'} pb={0}>
                                            {fixDate(item?.timestamp)}
                                        </Typography>
                                    </Grid>

                                </Grid>
                            
                            </CardContent>
                        </Card>
                    </Grid>

                ))}
            </Grid>
            
        </>
    )
};

export default AdminContactMessages;