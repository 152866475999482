import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { gqlDeleteNotification } from '../../gql';
import { NotificationViewType } from '../../enums/NotificationViewType';
import { gql, useMutation } from '@apollo/client';

import { useAppDispatch } from '../../redux/hooks';
import { setForceUpdate } from '../../redux/sliceNotifications';
import { ICustomSnackState } from '../custom-snack-bar/CustomSnackBar';
import { setLoading } from '../../redux/sliceLoading';

interface IDeleteNotificationButton {
    deleteId: string;
    viewType: NotificationViewType;
    snackState: ICustomSnackState;
    setCustomSnackState: React.Dispatch<React.SetStateAction<ICustomSnackState>> | null;
}

const DeleteNotificationButton:React.FunctionComponent<IDeleteNotificationButton> = ({
    deleteId, viewType, snackState, setCustomSnackState
}) => {

    // use redux toolkit dispatch
    const dispatch = useAppDispatch();

    // deleting a notification
    const [deleteNotificationId, setDeleteNotificationId] = useState("");
    const deleteNotifQuery = gqlDeleteNotification(deleteNotificationId, viewType === NotificationViewType.POPOVER ? false : null);
    const [deleteNotificationMutation, {loading, error, data}] = useMutation(
        gql`${deleteNotifQuery.query}`, { variables: deleteNotifQuery.variables }
    );

    const handleDeleteNotification = (id:string) => {
        setDeleteNotificationId(id);
    }

    useEffect(() => {

        if (deleteNotificationId !== "") {
            deleteNotificationMutation();
        }
    

    }, [deleteNotificationId, deleteNotificationMutation])

    useEffect(() => {

        if (data?.deleteNotification && deleteNotificationId !== "") {
            setDeleteNotificationId("");

            if (setCustomSnackState)
                setCustomSnackState({ ...snackState, open: true, severity: "success", message: "Notification Deleted!"});

            dispatch(setForceUpdate(true));
        }

        if (error)
            console.error(error);

    }, [data, error, snackState, deleteNotificationId, dispatch, setCustomSnackState]);

    // use redux toolkit dispatch
    useEffect(() => {
        dispatch(setLoading(loading));
    }, [loading, dispatch])
    
    return (
        <>
        
            <IconButton color="error" aria-label="delete notification" title='Delete notification'
                onClick={() => handleDeleteNotification(deleteId)}>
                <DeleteIcon />
            </IconButton>

        </>
    )
}

export default DeleteNotificationButton;