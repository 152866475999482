import React, { ReactNode } from 'react';

import { ApolloClient, ApolloProvider, InMemoryCache, HttpLink, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppSelector } from './redux/hooks';

import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from '@apollo/client/utilities';

type Props = {
    children: ReactNode
};

const ApolloConnection: React.FunctionComponent<Props> = ({ children }) => {
    const { loginWithRedirect, getAccessTokenSilently } = useAuth0();

    const discToken = useAppSelector((state) => state?.discogs?.oauth_token);
    const discSecret = useAppSelector((state) => state?.discogs?.oauth_secret);
    const discVerifier = useAppSelector((state) => state?.discogs?.oauth_verifier);

    const client = React.useRef<ApolloClient<any>>();
    const httpLink = new HttpLink({ uri: process.env.REACT_APP_API_URI });

    const wsLink = new GraphQLWsLink(
        createClient({
            url: process.env.REACT_APP_API_WS_URI!,
            connectionParams: async () => {
                
                let token;
                try {
                    //console.log("getAccessTokenSilently");
                    token = await getAccessTokenSilently({
                    authorizationParams: {
                        ignoreCache: true,
                        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                        scope: 'openid profile email read:listens',
                        redirect_uri: window.location.origin
                    },
                    });
                    //console.log("Auth0 Token", token);
                } catch (e:any) {
                    if (e.error === 'login_required')
                        loginWithRedirect();
                    else if (e.error === 'consent_required')
                        loginWithRedirect();
                    else if (e.error === 'missing_refresh_token')
                        loginWithRedirect();

                    console.error("e", e);
                    console.error("e.error", e.error);

                    throw e;
                }
                
                return {
                    Authorization: `Bearer ${token}`
                }
            }
        })
    );

    const splitLink = split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
        },
        wsLink,
        httpLink,
    );

    const authLink = setContext(async (_, { headers, ...rest }) => {
        let token;
        try {
            //console.log("getAccessTokenSilently");
            token = await getAccessTokenSilently({
              authorizationParams: {
                ignoreCache: true,
                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                scope: 'openid profile email read:listens',
                redirect_uri: window.location.origin
              },
            });
            //console.log("Auth0 Token", token);
        } catch (e:any) {
            if (e.error === 'login_required')
                loginWithRedirect();
            else if (e.error === 'consent_required')
                loginWithRedirect();
            else if (e.error === 'missing_refresh_token')
                loginWithRedirect();

            console.error("e", e);
            console.error("e.error", e.error);

            throw e;
        }

        if (!token) return { headers, ...rest};

        let discHeaders = {};
        if (discToken !== undefined && discToken !== ''
            && discSecret !== undefined && discSecret !== ''
            && discVerifier !== undefined && discVerifier !== '') {
            discHeaders = {
                'x-dt': discToken,
                'x-ds': discSecret,
                'x-dv': discVerifier
            };
        }

        return {
            ...rest,
            headers: {
                ...headers,
                Authorization: `Bearer ${token}`,
                ...discHeaders
            }
        }
    })

    if (!client.current) {
        const connectToDevTools = process.env.NODE_ENV === 'production' ? true : false;

        client.current = new ApolloClient({
            link: authLink.concat(splitLink),
            cache: new InMemoryCache({ addTypename: false}),
            connectToDevTools: connectToDevTools
        })
    }
 
    return (
        <ApolloProvider client={client.current}>
            {children}
        </ApolloProvider>
    )
}

export default ApolloConnection;