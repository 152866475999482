import React, { useEffect } from 'react';

import './ContactUs.scss';
import { FormControl, Grid, LinearProgress, TextField, Typography } from '@mui/material';

import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import CustomSnackBar, { ICustomSnackState } from '../custom-snack-bar/CustomSnackBar';
import { gqlContactUs } from '../../gql';
import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';

import SendIcon from '@mui/icons-material/Send';

import ReCAPTCHA from "react-google-recaptcha";

import { useStateWithCallbackLazy } from 'use-state-with-callback';

interface IContactMessage {
    subject: string,
    body: string,
    recaptcha: string,
}

const toggleCaptchaBadge = (show: boolean) => {
    const badge = document?.getElementsByClassName('grecaptcha-badge')[0];
    if (badge && badge instanceof HTMLElement) {
        badge.style.visibility = show ? 'visible' : 'hidden';
    }
};

const ContactUs:React.FunctionComponent = () => {

    const topRef = React.useRef<null | HTMLDivElement>(null);
    const recaptchaRef = React.useRef<ReCAPTCHA>(null);

    const [message, setMessage] = useStateWithCallbackLazy<IContactMessage>({ subject: '', body: '', recaptcha: ''});
    const [snackState, setSnackState] = React.useState<ICustomSnackState>({ open: false, 
        severity: "success", message: "", hideDuration: 5000});

    const contactQuery = gqlContactUs(message.subject, message.body, message.recaptcha);
    const [contact, {loading, error, data}] = useMutation(gql`${contactQuery.query}`, {
        variables: contactQuery.variables,
        fetchPolicy: 'network-only'
    });

    useEffect(() => { 
        toggleCaptchaBadge(true);
        recaptchaRef?.current?.reset();
    }, []);

    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        const captchaValue = await recaptchaRef?.current?.executeAsync();

        if (!captchaValue) {
            setSnackState({...snackState, severity: "error", open: true, message: "Please verify the reCAPTCHA!"});
        } else {
            setMessage({...message, recaptcha: captchaValue}, () => { contact(); });
        }

    }
    
    useEffect(() => {

        if (data?.contactEmail && message?.body !== '' && message?.subject !== '' && message?.recaptcha !== '') {

            if (data?.contactEmail?.success === true) {
                setMessage({ subject: '', body: '', recaptcha: ''}, () => { });
                setSnackState({...snackState, severity: "success", open: true, message: "Message sent!"});
                recaptchaRef?.current?.reset();
            } 

        }

        if (error) {
            setMessage({ subject: '', body: '', recaptcha: ''}, () => { });
            setSnackState({...snackState, severity: "error", open: true, message: "Error sending message!"});
            console.error(error);
        }

        // eslint-disable-next-line
    }, [data, error])

    return (
        <div>
            <div ref={topRef}></div>
            <Grid container className='page-header-row' mb={3}>
                <Grid item xs={12}><h1>Contact Us <AlternateEmailIcon fontSize='large' /></h1></Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    { (loading) && <LinearProgress color='secondary' /> }
                </Grid>
            </Grid>

            <CustomSnackBar setCustomSnackState={setSnackState} state={snackState} />

            <form>

                <Grid container>
                    <Grid item xs={12} mb={3}>
                        <FormControl fullWidth>
                            <TextField id="outlined-basic" label="Subject" variant="outlined"
                                type="search"
                                value={message?.subject} 
                                onChange={(e) => setMessage({...message, subject: e.currentTarget.value}, () => {})}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} mb={3}>
                        <FormControl fullWidth>
                            <TextField
                                sx={{ marginBottom: '0.25rem'}}
                                id="message-body"
                                label="Message"
                                multiline
                                rows={4}
                                value={message?.body}
                                onChange={(e) => setMessage({...message, body: e.currentTarget.value}, () => {})}
                            />
                            
                            <Typography variant='caption'>
                            This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target='_blank' rel='noreferrer'>Privacy Policy</a> and <a href="https://policies.google.com/terms" target='_blank' rel='noreferrer'>Terms of Service</a> apply.
                            </Typography>

                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>

                        <LoadingButton variant='contained' size='large' startIcon={<SendIcon />}
                            sx={{ 
                                marginRight: '2rem', 
                                marginBottom: '0.5rem',
                                width: { xs: '100%', md: 'auto' }
                            }}
                            loading={loading}
                            color="primary"
                            onClick={handleSubmit}
                            type='submit'>
                            Send Message
                        </LoadingButton>

                    </Grid>

                </Grid>

                <ReCAPTCHA ref={recaptchaRef} 
                    style={{ display: 'inline-block ' }}
                    size='invisible'
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
                    />
                
            </form>

        </div>
    )
}

export default ContactUs;