import React from 'react';

const DiscogsRelease:React.FunctionComponent = () => {

    return (
        <>
            Not Yet Implemented 
        </>
    )
}

export default DiscogsRelease;