import React from 'react';

import noImage from '../../assets/no_image.jpg';
import { Grid, Typography, Link } from '@mui/material';
import { Link as RouterLink } from "react-router-dom";

interface ISearchResultsRow {
    item: any;
}

const SearchResultsRow:React.FunctionComponent<ISearchResultsRow> = ({ item }) => {

    let itemType:string = "";
    let imageType:string = "";

    const maxLength = 25;

    switch (item?.type) {
        case "artist":
            itemType = "artist";
            imageType = "Band";
            break;
        case "release":
            itemType = "release";
            imageType = "Cover";
            break;
        case "master":
            itemType = "master";
            imageType = "Cover";
    }

    return (
        <Grid item xs={6} md={3} mb={2}>
            <Typography variant='caption' component={'div'}>
                <Link component={RouterLink} to={`../${itemType}/${item?.id}`}>
                    <img src={item?.thumb ? `${item?.thumb}` : `${noImage}`} alt={`${imageType} for ${item?.title}`}
                        title={`${item?.title}`} height={100} width={100} loading='lazy' /><br />
                    {item?.title?.split(' - ')[1].slice(0, maxLength)}{item?.title?.split(' - ')[1].length > maxLength && '...'}<br />
                    {item?.title?.split(' - ')[0].slice(0, maxLength)}{item?.title?.split(' - ')[0].length > maxLength && '...'}
                </Link>
            </Typography>
        </Grid>
    )
}

export default SearchResultsRow;