import React, { useEffect, useState } from 'react';

import './TimelineEdit.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Grid } from '@mui/material';

import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { LoadingButton } from '@mui/lab';
import { gqlEditListen } from '../../gql';
import { gql, useMutation } from '@apollo/client';
import CustomSnackBar, { ICustomSnackState } from '../custom-snack-bar/CustomSnackBar';
import TimelineEntryLayout from '../TimelineEntryLayout/TimelineEntryLayout';
import { useAppSelector } from '../../redux/hooks';

dayjs.extend(utc);

const TimelineEdit:React.FunctionComponent = () => {
    const data = useLocation();
    const item = data?.state;
    const nav = useNavigate();
    const user = useAppSelector((state) => state.user.value);
    const [listendWith, setListenedWith] = useState<any[]>(item?.listenedWith);
    const [rating, setRating] = useState(item?.rating);

    const [notes, setNotes] = useState(item?.notes);
    const [datetime, setDatetime] = useState<Dayjs | null>(dayjs(item?.timestamp * 1000));
    
    const editListenMutation = gqlEditListen(item?._id, notes, dayjs.utc(datetime).utc().unix(), rating, listendWith);
    const [editListen, { loading:editLoading, error:editError, data:editData }] = useMutation(
        gql`${editListenMutation.query}`, { variables: editListenMutation.variables, errorPolicy: 'all'}
    )

    const handleEditListen:React.MouseEventHandler = () => {
        editListen();
    }

    // snackbar
    const [snackState, setSnackState] = useState<ICustomSnackState>({ open: false, 
        severity: "success", message: "", hideDuration: 5000});


    useEffect(() => {
        if (editData?.editListen?.success) {
            setSnackState({ ...snackState, open: true, severity: "success", message: "Timeline entry edited!"});
            setTimeout(() => { nav('../../timeline') }, 1500);
            ;
        }

        if (editError) {
            setSnackState({ ...snackState, open: true, severity: "error", message: "There was an error updating this timeline entry"});
            console.error(editError);
        }
            
    }, [editData, editError, nav]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleCancel = () => {
        nav('../../timeline');
    }

    return (
        <div>

            <CustomSnackBar setCustomSnackState={setSnackState} state={snackState} />

            <Grid container className='page-header-row' mb={3}>
                <Grid item xs={12}><h1>Edit timeline entry</h1></Grid>
            </Grid>

            <TimelineEntryLayout user={user} title={item?.listenedTo?.title} thumb={item?.listenedTo?.thumb} 
                artists={item?.listenedTo?.artists_sort} notes={notes} setNotes={setNotes} 
                datetime={dayjs(item?.timestamp * 1000)} setDatetime={setDatetime}
                listenedWith={listendWith} setListenedWith={setListenedWith}
                rating={rating} setRating={setRating} />

            <Grid container mb={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <LoadingButton variant="contained" size="large"
                        sx={{ width: { xs: '100%', md: 'auto' },
                            marginBottom: '1rem', marginRight: { xs: '0rem', md: '1rem'} }}
                        loading={editLoading}
                        disabled={editData?.editListen?.success}
                        onClick={handleEditListen} title='Edit entry'>Update entry</LoadingButton>
                    <Button variant="outlined" size="large"
                        sx={{ width: { xs: '100%', md: 'auto'}, marginBottom: '1rem', marginRight: { xs: '0rem', md: '1rem'} }}
                        onClick={handleCancel}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>

        </div>
    )
}

export default TimelineEdit;